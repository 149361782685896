//import components
import {
    Home,
    Login,
    MagicLink,
    ProfileDetail,
    EditProfile,
    LinkExpired,
    Members,
    PrivacyPolicy,
    TermsAndConditions,
    CompaniesHome,
    About,
    Moderation,
    Meetings
  } from "../views";

export   const routes = [
    {
      path: "/splash",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/moderation",
      element: <Moderation />,
    },
    {
      path: "/meetings",
      element: <Meetings />,
    },
    {
      path: "/companies",
      element: <CompaniesHome />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions />,
    },
    {
      path: "/",
      element: <Members />,
    },
    {
      path: "/profile/:id",
      element: <ProfileDetail />,
      onlyLoggedIn: true,
    },
    {
      path: "/login",
      element: <Login />,
      onlyLoggedOut: true,
    },
    {
      path: "/login-link",
      element: <MagicLink />,
      onlyLoggedOut: true,
    },
    {
      path: "/profile/edit/overview",
      element: <EditProfile />,
      onlyLoggedIn: true,
    },
    {
      path: "/profile/edit/about",
      element: <EditProfile />,
      onlyLoggedIn: true,
    },
    {
      path: "/link-expired",
      element: <LinkExpired />,
      onlyLoggedOut: true,
    },
  ]