import React, { useRef } from "react";

//import components and functions
import { Navbar, Notification } from "../../components/index.js";

//import styles and variables
import "./About.scss";
import { links } from "../../constants/data.js";

const Members = () => {

  // refs
  const notificationRef = useRef();

  return (
    <div className="about__main">
      <Navbar home noAbsolute />
      <div className="about__main__container">
        <div className="about__text__container">
          <div className="about__main__heading">
            <span>Life is Meeting</span> will help you think for yourself, together, through in person meetings where strangers come together to discover how they could think about...
          </div>
          <div className="about__main__heading m__top">
            We currently live on Substack where we are the <a href={links?.education} target="_blank">5th rated Education substack in the world</a>
          </div>
        </div>
      </div>
      <Notification notificationRef={notificationRef} />
    </div>
  );
};

export default Members;
