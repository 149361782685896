import React from 'react';
import { RoundedBtn } from '../../components';

//import styles and images
import {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5
} from '../../assets/images/avatars';
import './review.scss';

const Review = ({description}) => {

  const reviewImages = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5]

  return (
    <div className='review__section__main'>
      <div className='heading__text start__meeting'>
        <span>Let’s Start Meeting </span>Like This
      </div>
      <div className='review__description'>
        {description}
      </div>
      <div className='text-center'>
        <RoundedBtn text={"Join Now"} bgYellow />
      </div>
      <div className="review__images__section">
        <div className='d-flex justify-content-center'>
          {reviewImages.map((image, index) => (<img className={index !== 0 && "not_first"} src={image} key={index} />))}
        </div>
        <div className='text__after__review'>
        Join 5000+ others
        </div>
      </div>
    </div>
  )
}

export default Review