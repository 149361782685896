import React from "react";

//import components and variables
import { Tooltip } from "antd";
import { theme } from "../../../constants/data";

const ImageBox = ({ index, data, onProfileClick }) => {
  const currentData = data[index];
  return (
    <div
      className={`${currentData && "bg-light"} image__box ${currentData && "border__none"
        }`}
    >
      {currentData && (
        <Tooltip
          color={theme.black}
          title={`${currentData?.firstName} ${currentData?.lastName}`}
        >
          <img
            onClick={() => onProfileClick(currentData?.postLink)}
            alt={currentData?._id}
            src={currentData?.profileImageThumbnail}
            className="image"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ImageBox;
