import React, { useState } from "react";
import { useSelector } from "react-redux";

//import components from dependencies
import { Drawer, Space } from "antd";

//import components
import { CloseIcon } from "../../assets/Icons";
import { RequestCard, RoundedBtn } from "../../components";

//import styles
import "./Drawer.scss";

const ConnectionRequests = ({ openDrawer, setOpenDrawer, fetchConnections }) => {
  const { windowWidth, connectionReq, requestsSent } = useSelector((state) => state);

  const [activeTab, setActiveTab] = useState("connectionRequest")

  const onClose = () => {
    setOpenDrawer(false);
  };

  const changeActiveTab = (tab) => {
    setActiveTab(tab)
  }

  return (
    <>
      <Space></Space>
      <Drawer
        title={
          <div className="title__container">
            <div className="center__line"></div>
            <div>Connection Request</div>
          </div>
        }
        placement="right"
        width={windowWidth > 600 ? 600 : windowWidth}
        onClose={onClose}
        open={openDrawer}
        closeIcon={<CloseIcon />}
      >
        <div className="connection__tabs">
          <RoundedBtn
            text={"Pending Connections"}
            bgYellow={activeTab === "connectionRequest"}
            classes={activeTab !== "connectionRequest" && "no_bg_no_border dark__on__hover p-0 m-0"}
            onClick={() => changeActiveTab("connectionRequest")}
          />
          <RoundedBtn
            text={"Sent Connections"}
            bgYellow={activeTab === "requestSent"}
            classes={activeTab !== "requestSent" && "no_bg_no_border dark__on__hover p-0 m-0"}
            onClick={() => changeActiveTab("requestSent")}
          />
        </div>
        {activeTab === "connectionRequest" ? (
          <>
            {connectionReq?.length > 0 ? (
              <div>
                {connectionReq?.map((v, i) => (
                  <RequestCard onClose={onClose} fetchConnections={fetchConnections} data={v} key={i} />
                ))}
              </div>
            ) : (
              <div className="not__found__container">
                <div>
                  <p className="text-center not__found__heading m-0">
                    Your connection request <span className="font_italic">live</span> here
                  </p>
                  <p className="text-center not__found__description m-0">
                    You're all caught up! There are no pending connection requests
                    to review.
                  </p>
                </div>
              </div>
            )}
          </>
        ) :
          (
            <>
              {requestsSent?.length > 0 ? (
                <div>
                  {requestsSent?.map((v, i) => (
                    <RequestCard requestSent onClose={onClose} fetchConnections={fetchConnections} data={v} key={i} />
                  ))}
                </div>
              ) : (
                <div className="not__found__container">
                  <div>
                    <p className="text-center not__found__heading m-0">
                      The connection requests you've sent <span className="font_italic">live</span> here!
                    </p>
                    <p className="text-center not__found__description m-0">
                      You've got a clean slate - no outstanding sent connections here!
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
      </Drawer>
    </>
  );
};
export default ConnectionRequests;
