import React, { useState } from "react";

//import components and functions
import BlackButton from "../buttons/BlackButton";
import Spinner from "../loaders/Spinner";
import { updateConnectRequest } from "../../api/api";

// import styles
import { theme } from "../../constants/data";
import "./Card.scss";
import { useNavigate } from "react-router-dom";
import RoundedBtn from "../buttons/RoundedBtn";

const RequestCard = ({ data, fetchConnections, onClose, requestSent }) => {

  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate()

  // request accept or reject function
  const connectionUpdate = async (connectionStatus) => {
    setBtnLoading(connectionStatus)
    await updateConnectRequest({ connectionStatus, id: data?._id });
    await fetchConnections()
    setBtnLoading("")
  }

  const navigateToUserProfile = ()=>{
    onClose()
    navigate(`/profile/${data?.senderId}`)
  }

  return (
    <div className="request__card">
      <div>
        <img onClick={navigateToUserProfile} src={data?.profileImageFull} alt={data?.senderFullName} />
      </div>
      <div>
        <div onClick={navigateToUserProfile} className="user__name pointer">{data?.senderFullName}</div>
        {!requestSent ? <div className="d-flex gap-3 mt-2">
          <RoundedBtn onClick={() => connectionUpdate("accepted")} classes={"btn__custom"} bgYellow text={btnLoading === "accepted" ? <Spinner color={theme.white} size={14} /> : "Confirm"} />
          <RoundedBtn onClick={() => connectionUpdate("rejected")} classes={`btn__custom btn__gray`} text={btnLoading === "rejected" ? <Spinner color={theme.white} size={14} /> : "Delete"} />
        </div>:
        <div className="d-flex gap-3 mt-2">
        <RoundedBtn classes={"btn__custom"} bgYellow text={"Awaiting"} />
      </div>}
      </div>
    </div>
  );
};

export default RequestCard;
