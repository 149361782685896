import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  updateLoginStatus,
  updateToPrice,
} from "../../../redux/reducers/appreducer";

//import components
import RoundedBtn from "../../buttons/RoundedBtn";
import NavbarDrawerNew from "../../drawer/NavbarDrawerNew";

//import styles images and icons
import { ArrowDown, MenuIconNew } from "../../../assets/Icons";
import { dropdownItems, navbarLinks } from "../../../constants/navbarData";
import logo from "../../../assets/images/svgImages/lim_logo.svg";
import "./NewNavbar.scss";
import { Dropdown, Button } from "antd";
// import 'antd/dist/antd.css';

const items = dropdownItems.map((v, i) => ({
  key: i,
  label: (
    <Link to={v.link} target="_blank" className="option__card">
      <div>
        <div style={{ backgroundColor: v.color }} className="bullet" />
      </div>
      <div>
        <div className="name">{v.name}</div>
        <div className="description">{v.description}</div>
      </div>
    </Link>
  ),
}));

const Index = ({ pricingRef }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { loginStatus } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const navLinks = navbarLinks(pricingRef);

  const toggleDrawer = () => setOpenDrawer((dr) => !dr);

  const clickScrollLink = (ref) => {
    if (location.pathname !== "/splash") {
      dispatch(updateToPrice(true));
      navigate("/splash");
    } else {
      const element = ref.current;
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const login = () => {
    navigate("/login");
  };

  const logout = () => {
    localStorage.clear();
    dispatch(updateLoginStatus(false));
  };

  const dashboardClick = () => {
    navigate("/");
  };


  return (
    <div className="new__navbar__main">
      <div className="nav__section">
        <div className="menu__icon pointer">
          <button onClick={toggleDrawer}>
            <MenuIconNew />
          </button>
        </div>
        <div className="logo__section">
          <Link to="/splash">
            <img src={logo} />
          </Link>
        </div>
        <div className="links__section d-flex aign-items-center position-relative">
          {navLinks?.map((v, i) => {
            return v.type === "dropdown" ? (
              <Dropdown
                overlayClassName="main__dropdown"
                menu={{ items }}
                placement="bottom"
              >
                <button
                  className="d-flex align-items-center gap-1 dropdown__btn"
                >
                  <div>{v.name}</div>
                  <div>
                    <ArrowDown />
                  </div>
                </button>
              </Dropdown>
            ) : v.type === "linkScroll" ? (
              <div
                onClick={() => clickScrollLink(v.scrollRef)}
                className="pointer"
                key={i}
              >
                {v.name}
              </div>
            ) : (
              <Link target={v.newTab && "_blank"} to={v.link}>
                {v.name}
              </Link>
            );
          })}
        </div>
        {loginStatus ? (
          <div className="btns__section">
            <RoundedBtn onClick={logout} text={"Logout"} />
            <RoundedBtn text={"Dashboard"} bgYellow onClick={dashboardClick} />
          </div>
        ) : (
          <div className="btns__section">
            <RoundedBtn onClick={login} text={"Login"} />
            <RoundedBtn text={"Join Now"} bgYellow />
          </div>
        )}
      </div>
      <NavbarDrawerNew
        dashboardClick={dashboardClick}
        loginStatus={loginStatus}
        clickScrollLink={clickScrollLink}
        login={login}
        dropdownItems={dropdownItems}
        links={navLinks}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        logout={logout}
      />
    </div>
  );
};

export default Index;
