import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Spinner = ({color,size}) => {
    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize:size ?? 24,
            color:color
          }}
          spin
        />
      );
    return <Spin indicator={antIcon} />
};
export default Spinner;