import React, { useRef, useEffect, useState } from "react";
//import components and functions
import {
  BlackButton,
  Notification,
  Spinner,
  TextInput,
} from "../../../components";
import { updateProfile } from "../../../api/api";
import { getProfileValidtions } from "../../../functions/validations";

//import styles and variables
import { theme , texts} from "../../../constants/data";
import "../EditProfile.scss";

const Details = ({ getPayload, data , fetchUserData }) => {
  //refs
  const notificationRef = useRef();

  //states
  const [profileData, setProfileData] = useState({ verbs: [] });
  const [isLoading, setIsLoading] = useState(false);

  const validation = getProfileValidtions(profileData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((data) => ({ ...data, [name]: value }));
  };

  const handleChangeVerb = (e, i) => {
    const verbs = profileData.verbs;
    verbs[i] = e;
    setProfileData((data) => ({ ...data, verbs }));
  };

  //update profile function
  const updateUserProfile = async () => {
    setIsLoading(true);
    const payload = getPayload(profileData, "profile");
    const response = await updateProfile(payload);
    if (response.success) {
      notificationRef.current.openNotification("Your profile has been successfully updated",true);
      fetchUserData()
    } else {
      notificationRef.current.openNotification("Something went wrong",false);
    }
    setIsLoading(false);
  };

  const aboutUserLabel = (
    <div className="input__label">
      You can write anything about yourself that you prefer to share with the
      community, however it would be great if you could try to answer these
      three questions: <span>*</span>
      <ul>
        <li>What’s a success or achievement you’re most proud of?</li>
        <li>What’s a failure you’ve learnt the most from?</li>
        <li>Something you’re currently struggling with?</li>
      </ul>
    </div>
  );

  useEffect(() => {
    setProfileData({
      yourStory: data?.userPersonalInfo?.yourStory,
      tellUsAndAskYours: data?.userPersonalInfo?.tellUsAndAskYours,
      verbs: data?.userPersonalInfo?.verbs ?? [],
    });
  }, [data]);

  return (
    <div className="input__section pb-4">
      <div className="w-100 mt-3">
        <TextInput
          onchange={(e) => handleChangeVerb(e.target.value, 0)}
          label={`${texts.verb} (required - we’d like three)`}
          name="verb1"
          classes="mt-3"
          placeholder={"Verb 1"}
          maxLengthRequire
          max={50}
          value={profileData?.verbs[0] ?? ""}
          required
        />
        <TextInput
          maxLengthRequire
          onchange={(e) => handleChangeVerb(e.target.value, 1)}
          name="verb2"
          placeholder={"Verb 2"}
          max={50}
          value={profileData?.verbs[1] ?? ""}
        />
        <TextInput
          maxLengthRequire
          onchange={(e) => handleChangeVerb(e.target.value, 2)}
          name="verb3"
          placeholder={"Verb 3"}
          max={50}
          value={profileData?.verbs[2] ?? ""}
        />
        <TextInput
          onchange={handleChange}
          label={aboutUserLabel}
          name="yourStory"
          classes="aboutUser__input"
          textarea
          value={profileData.yourStory}
        />
        <TextInput
          onchange={handleChange}
          name="tellUsAndAskYours"
          label={"Who do you admire and why?"}
          required
          max={50}
          maxLengthRequire
          value={profileData.tellUsAndAskYours}
          classes={"mt-50"}
        />
        <BlackButton
          onclick={updateUserProfile}
          classes="w-100 my-4"
          text={isLoading ? <Spinner color={theme.white} /> : "Save"}
          disabled={!validation || isLoading}
        />
      </div>
      <Notification notificationRef={notificationRef} />
    </div>
  );
};

export default Details;
