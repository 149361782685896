import { Navigation, A11y, Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Carousel.scss'
import { useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from '../../assets/Icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default ({items}) => {

    const swipRef = useRef(null);

    const [activeWidth, setActiveWidth] = useState(0)
    const [inActiveWidth, setInActiveWidth] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0)
    const [originalWidth, setOriginalWidth] = useState(0)
    const [hoverIndex, setHoverIndex] = useState(null)
    const [height, setHeight] = useState(0)
    const { windowWidth } = useSelector(state => state)
    const [slidesPerView, setSlidesPerView] = useState(windowWidth < 526 ? 1 : windowWidth < 769 ? 3 : 5)
    const [itemsArr, setItemsArr] = useState(items)

    useEffect(() => {
        const slidesPerView = windowWidth < 526 ? 1 : windowWidth < 769 ? 3 : 5
        setSlidesPerView(slidesPerView)
        const activeWidth = windowWidth < 526 ? swipRef?.current?.offsetWidth : ((swipRef?.current?.offsetWidth / 100) * (slidesPerView === 3 ? 50 : 30))
        const inActive = (swipRef.current?.offsetWidth - activeWidth - ((slidesPerView - 1) * 11)) / (slidesPerView - 1)
        setOriginalWidth(swipRef?.current?.offsetWidth / slidesPerView)
        setInActiveWidth(inActive)
        setActiveWidth(activeWidth)
        setHeight((activeWidth * 448.75) / 359)
    }, [swipRef, windowWidth])

    const handleMouseEnter = (index) => setHoverIndex(index)
    const handleMouseOut = () => setHoverIndex(null)

    useEffect(() => {
        const newArr = [...items]
        for (var i = 0; i < slidesPerView - 1; i++) {
            newArr.push({ img: null })
        }
        setItemsArr(newArr)
    }, [slidesPerView,items])


    return (
        <>

            <Swiper
                // install Swiper modules
                modules={windowWidth < 526 ? [A11y, Pagination] : [Navigation, A11y]}
                isDuplicate

                spaceBetween={50}
                slidesPerView={slidesPerView}
                navigation={{
                    nextEl: '.custom-next-button',
                    prevEl: '.custom-prev-button',
                }}
                pagination={{ clickable: true }}
                onSwiper={(s) => setActiveIndex(s.activeIndex)}
                onSlideChange={(s) => setActiveIndex(s.activeIndex)}
                ref={swipRef}
                style={{ height: windowWidth < 526 ? "auto" : height }}
            >
                {itemsArr?.map((v, i) =>
                    <SwiperSlide
                        onMouseOver={
                            () => v.img && handleMouseEnter(i)
                        } onMouseOut={v.img && handleMouseOut}
                        className={`${i < activeIndex && 'previous_data'}  ${hoverIndex === i && "hovered"}`} key={i}>
                        {v.img && <img className='img' src={v.img} />}
                        <div className='review__section'>
                            <div className='review__subsection'>
                                <div className='mesg'>
                                    {v.review?.message}
                                </div>
                                <div className='name'>
                                    {v.review?.name}
                                </div>
                                <div className='address'>
                                    {v.review?.address}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>)}
                {/* Custom Navigation Buttons */}
            </Swiper>
            {windowWidth > 525 && <div className='d-flex justify-content-end gap-4 mt-4 mx-1'>
                <button className="custom-prev-button btn-custom"><ArrowLeft /></button>
                <button className="custom-next-button btn-custom"><ArrowRight /></button>
            </div>}
            {/* <style>
                {`
    .swiper-slide {
        .img{
            filter: grayscale(0);
        }
   }
    @media only screen and (min-width: 526px) {
        .swiper-slide {
        width: ${inActiveWidth}px !important;
        margin:0 !important;
        padding:0px 2px;
        transition:width .25s;
        .review__section{
            display:none;
        }
        .img{
            filter: grayscale(100%);
                }
        &.swiper-slide-active{
            width: ${!hoverIndex ? activeWidth : inActiveWidth}px !important;
            .review__section{
            display:${!hoverIndex ? "block" : inActiveWidth} !important;
        }
        .img{
            filter: grayscale(${!hoverIndex ? 0 : "100%"});
        }
        }
        &.previous_data{
            width:${originalWidth}px !important;
        }
        &.hovered{
            width: ${activeWidth}px !important;
            .review__section{
            display:block !important;
        }
            .img{
            filter: grayscale(0);
        }
        }
      }

     }
    `}
            </style> */}
        </>

    );
};