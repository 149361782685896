import React, { useMemo } from "react";

//import components and icons
import { notification } from "antd";
import { BsCheck2 } from "react-icons/bs";
import { MdErrorOutline } from "react-icons/md";

const Context = React.createContext({
  name: "Default",
});
const Notification = ({ notificationRef }) => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message,success) => {
    api.info({
      message: `${message}`,
      description:"",
      placement: "topRight",
      icon:success ? <BsCheck2 color="green" /> :<MdErrorOutline style={{marginTop:-4}} color="red" />,
    });
  };
  
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  notificationRef.current = {
    openNotification,
  };

  return (
    <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
  );
};
export default Notification;
