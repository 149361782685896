import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//import components from dependencies
import { Drawer, Space } from "antd";

//import components
import { ArrowDown, CloseiconNew } from "../../assets/Icons";
import RoundedBtn from "../buttons/RoundedBtn";

//import styles
import "./Drawer.scss";
import Logo from "../../assets/images/svgImages/lim_logo.svg";

const NavbarDrawerNew = ({
  openDrawer,
  setOpenDrawer,
  login,
  links,
  dropdownItems,
  logout,
  clickScrollLink,
  loginStatus,
  dashboardClick
}) => {
  const windowWidth = useSelector((state) => state.windowWidth);
  const navigate = useNavigate();

  const onClose = () => {
    setOpenDrawer(false);
  };

  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => setOpenDropdown((d) => !d);

  const homeClick = () => {
    onClose();
    navigate("/");
  };

  return (
    <>
      <Space></Space>
      <Drawer
        placement="right"
        width={windowWidth > 600 ? 600 : windowWidth}
        onClose={onClose}
        open={openDrawer}
        closable={false}
        bodyStyle={{ backgroundColor: "#FFF" }}
        headerStyle={{ backgroundColor: "#FFF", borderWidth: 0, padding: 0 }}
        footerStyle={{ backgroundColor: "#FFF", borderWidth: 0 }}
        style={{ backgroundColor: "#FFF" }}
        contentWrapperStyle={{ padding: 15, backgroundColor: "#F5F5F5" }}
        className="new__drawer__main"
        footer={
          <DrawerFooter
            loginStatus={loginStatus}
            login={login}
            logout={logout}
            dashboardClick={dashboardClick}
          />
        }
      >
        <div className="content__section__new">
          <div className="border__box">
            <div className="d-flex w-100 justify-content-between">
              <img
                className="pointer"
                onClick={homeClick}
                src={Logo}
                alt="logo"
              />
              <div onClick={onClose} className="pointer">
                <CloseiconNew />
              </div>
            </div>
            <div className="Link___section">
              {links.map((v, i) => {
                return v.type === "dropdown" ? (
                  <div>
                    <button
                      onClick={toggleDropdown}
                      className="d-flex align-items-center gap-1 dropdown__btn"
                    >
                      <div>{v.name}</div>
                      <div>
                        <ArrowDown />
                      </div>
                    </button>
                    {openDropdown && (
                      <div className="drawer__dropdown">
                        {dropdownItems.map((v, i) => (
                          <Link
                            target="_blank"
                            to={v.link}
                            className="d-flex align-items-center list__main"
                          >
                            <div>
                              <div
                                className="bullet"
                                style={{ backgroundColor: v.color }}
                              />
                            </div>
                            <div className="list__text">{v.name}</div>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : v.type === "linkScroll" ? (
                  <div
                    onClick={() => {
                      onClose();
                      clickScrollLink(v.scrollRef);
                    }}
                    className="pointer"
                    key={i}
                  >
                    {v.name}
                  </div>
                ) : (
                  <Link target={v.newTab && "_blank"} to={v.link}>
                    {v.name}
                  </Link>
                );
              })}
            </div>
            {/* {loginStatus ? <div className="Button__section">
              <div>
                <RoundedBtn onClick={logout} text={"Logout"} />
              </div>
            </div> : <div className="Button__section">
              <div>
                <RoundedBtn onClick={login} text={"Login"} />
              </div>
              <div>
                <RoundedBtn text="Join now" bgYellow />
              </div>
            </div>} */}
          </div>
        </div>
      </Drawer>
    </>
  );
};

const DrawerFooter = ({ loginStatus, login, logout, dashboardClick }) => {
  return (
    <div className="drawer__footer_new">
      {loginStatus ? (
        <div className="Button__section">
          <div>
            <RoundedBtn onClick={logout} text={"Logout"} />
          </div>
          <div>
              <RoundedBtn onClick={dashboardClick} text={"Dashboard"} bgYellow />
          </div>
        </div>
      ) : (
        <div className="Button__section">
          <div>
            <RoundedBtn onClick={login} text={"Login"} />
          </div>
          <div>
            <RoundedBtn text="Join now" bgYellow />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarDrawerNew;
