import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//import components and functions
import { Navbar, RoundedBtn } from "../../components";
import { getEmail } from "../../functions/localstorage";
import { getInitialScreen } from "../../functions/common";

//imprort styles and varriables
import { links } from "../../constants/data";
import "../Login/Login.scss";

const MagicLink = () => {

  const { windowWidth } = useSelector((state) => state);
  const [size, setSize] = useState(getInitialScreen);

  const email = getEmail()

  useEffect(() => {
    setSize(getInitialScreen(true))
  }, [windowWidth])

  return (
    <div className="m-auto"  style={{ width: "100%" }}>
      <div className="navbar__login"></div><Navbar />
      <div className="login__container">
        <div className="input__section">
          <div className="heading_login">Check your email</div>
          <div className="description mt-3">
            We sent you an email at {email}. It has a magic link that
            will sign you in
          </div>
          <a href={links.gmailLink} className="d-flex justify-content-center text-decoration-none" rel="noopener noreferrer" target="_blank">
            <RoundedBtn
              classes=" mt-4 custom__f__size"
              text={"Open Mail App"}
              bgYellow={true}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MagicLink;
