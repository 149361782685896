import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//import components from dependencies
import { Drawer, Space } from "antd";

//import components
import { ArrowDown, CloseiconNew } from "../../assets/Icons";
import RoundedBtn from "../buttons/RoundedBtn";

//import styles
import "./Drawer.scss";
import Logo from "../../assets/images/svgImages/lim_logo.svg";
import { links } from "../../constants/data";

const NavbarDrawerNew = ({
  openDrawer,
  setOpenDrawer,
  login,
  dropdownItems,
  logout,
  clickScrollLink,
  loginStatus,
  dashboardClick,
  connectionDrawer,
  searchDrawerToggle
}) => {
  const windowWidth = useSelector((state) => state.windowWidth);
  const navigate = useNavigate();

  const onClose = () => {
    setOpenDrawer(false);
  };

  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => setOpenDropdown((d) => !d);

  const homeClick = () => {
    onClose();
    navigate("/");
  };

  // const buttons = loginStatus?  [
  //   {
  //     name: "Search",
  //     onClick: searchDrawerToggle
  //   },
  //   {
  //     name: "Notifications",
  //     onClick: connectionDrawer
  //   },
  //   {
  //     name: "Media",
  //     link: links.media,
  //     newTab: true
  //   },
  //   {
  //     name: "Meetings",
  //     link: links.meetings,
  //     newTab: true
  //   },
  // ]:[
  //   {
  //     name: "Media",
  //     link: links.media,
  //     newTab: true
  //   },
  //   {
  //     name: "Meetings",
  //     link: links.meetings,
  //     newTab: true
  //   },
  // ]
  const buttons = [
    {
      name: "About",
      link: "/about",
      newTab: false
    },
    {
      name: "Media",
      link: links.media,
      newTab: true
    },
    {
      name: "Meetings",
      link: links.meetings,
      newTab: true
    },
    {
      name: "Moderation",
      link: "/moderation",
      newTab: false
    },
  ]

  return (
    <>
      <Space></Space>
      <Drawer
        placement="right"
        width={windowWidth > 600 ? 600 : windowWidth}
        onClose={onClose}
        open={openDrawer}
        closable={false}
        bodyStyle={{ backgroundColor: "#FFF" }}
        headerStyle={{ backgroundColor: "#FFF", borderWidth: 0, padding: 0 }}
        footerStyle={{ backgroundColor: "#FFF", borderWidth: 0 }}
        style={{ backgroundColor: "#FFF" }}
        contentWrapperStyle={{ padding: 15, backgroundColor: "#F5F5F5" }}
        className="new__drawer__main"
        footer={
          <DrawerFooter
            loginStatus={loginStatus}
            login={login}
            logout={logout}
            dashboardClick={dashboardClick}
          />
        }
      >
        <div className="content__section__new">
          <div className="border__box">
            <div className="d-flex w-100 justify-content-between">
              <img
                className="pointer"
                onClick={homeClick}
                src={Logo}
                alt="logo"
              />
              <div onClick={onClose} className="pointer">
                <CloseiconNew />
              </div>
            </div>
            <div className="Link___section">
              {buttons.map((v, i) => {
                return v.onClick ? (
                  <div
                    onClick={v.onClick}
                    className="pointer"
                    key={i}
                  >
                    {v.name}
                  </div>
                ) : (
                  <Link target={v.newTab && "_blank"} to={v.link}>
                    {v.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const DrawerFooter = ({ loginStatus, login, logout, dashboardClick }) => {
  return (
    <div className="drawer__footer_new">
      {/* {loginStatus ? (
        <div className="Button__section">
          <div>
            <RoundedBtn onClick={logout} text={"Logout"} />
          </div>
        </div>
      ) : (
        <div className="Button__section">
          <div>
            <RoundedBtn onClick={login} text={"Login"} bgYellow />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default NavbarDrawerNew;
