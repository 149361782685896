import { network_member1, network_member2, network_member3, network_member4, network_member5 } from '../assets/images/networkMembers'
import member1 from '../assets/images/membersNew/slider1.jpg'
import member2 from '../assets/images/membersNew/slider2.jpg'
import member3 from '../assets/images/membersNew/slider3.jpg'

export const networkMemberCarousel = [
    {
        img: member1,
        review: {
            message: '“Do you think the future will be more or less peaceful than the past?”',
            name: "Michelle Doe",
            address: "Writing in New York City"
        }
    },
    {
        img: member2,
        review: {
            message: '“What strong belief do you currently have where you might be wrong but don’t think you are?”',
            name: "Michelle Doe",
            address: "Moderating in Los Angeles"
        }
    },
    {
        img: member3,
        review: {
            message: '“How do you think about advice?”',
            name: "Michelle Doe",
            address: "Speaking in Boston"
        }
    },
    {
        img: network_member4,
        review: {
            message: '“Do you think the future will be more or less peaceful than the past?”',
            name: "Michelle Doe",
            address: "Writing in New York City"
        }
    },
    {
        img: network_member5,
        review: {
            message: '“Do you think the future will be more or less peaceful than the past?”',
            name: "Michelle Doe",
            address: "Writing in New York City"
        }
    }
]
export const foundingMemberCarouselData = [
    {
        img: member1,
        review: {
            message: '“Do you think the future will be more or less peaceful than the past?”',
            name: "Michelle Doe",
            address: "Writing in New York City"
        }
    },
    {
        img: member3,
        review: {
            message: '“How do you think about advice?”',
            name: "Michelle Doe",
            address: "Speaking in Boston"
        }
    },
    {
        img: member2,
        review: {
            message: '“What strong belief do you currently have where you might be wrong but don’t think you are?”',
            name: "Michelle Doe",
            address: "Moderating in Los Angeles"
        }
    },
    {
        img: network_member5,
        review: {
            message: '“Do you think the future will be more or less peaceful than the past?”',
            name: "Michelle Doe",
            address: "Writing in New York City"
        }
    },
    {
        img: network_member3,
        review: {
            message: '“Do you think the future will be more or less peaceful than the past?”',
            name: "Michelle Doe",
            address: "Writing in New York City"
        }
    }
]