import React from "react";
import "./subHero.scss";

const Index = ({ heading, description, secondDescription }) => {
  return (
    <div className="sub_hero__main row mx-0">
      <div className="col-md-6 m-0 p-0">
        <div className="sub__hero_heading w-100">{heading}</div>
      </div>
      <div className={`col-md-6 sub__hero_description m-0`}>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
        {secondDescription && <div className="second__description">{secondDescription}</div>}
      </div>
    </div>
  );
};

export default Index;
