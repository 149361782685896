import React from 'react';
import './components.scss'
import { RoundedBtn } from '../../../components';
import member1 from '../../../assets/images/membersNew/member1.png';
import member2 from '../../../assets/images/membersNew/member2.png';


const Card = ({ image, numbers, textBelowNumber, details }) => {
    return <div className='col-md-6'>
        <div className='img__box'>
            <img src={image} />
        </div>
        <div className='text__section d-flex'>
            <div className='col-6'>
                <div className='number'>{numbers}</div>
                <div className='number__text'>{textBelowNumber}</div>
            </div>
            <div className='col-6 details'>
                {details}
            </div>
        </div>
    </div>
}

const Updates = () => {
    return (
        <div className='updates__main'>
            <div className='heading__section'>
                <div className='heading__text'>
                    <span>We are a new kind of</span> social network, <span> meeting in-person and online.</span>
                </div>
                <div className='btn__section'>
                    <RoundedBtn text={"Join Now"} classes={"bg__yellow"} />
                </div>
            </div>
            <div className='details__section row m-0 p-0'>
                <Card
                    image={member1}
                    numbers="1,000"
                    textBelowNumber="Members"
                    details="Free access to meetings, media, and each other."
                />
                <Card
                    image={member2}
                    numbers="25"
                    textBelowNumber="Creators"
                    details="Create and host meetings, moderate, or contribute to our media."
                />
            </div>
        </div>
    )
}

export default Updates;