import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CloseIcon } from "../../assets/Icons";

//import components and functions
import Personal from "./partials/Personal";
import Details from "./partials/Details";
import { removeToken } from "../../functions/localstorage";
import { updateLoginStatus } from "../../redux/reducers/appreducer";
import { fetchMe } from "../../api/api";

//import styles and variables
import { theme } from "../../constants/data";
import "./EditProfile.scss";

const Login = () => {

  //router hooks
  const navigate = useNavigate();
  const location = useLocation();

  //redux hooks
  const dispatch = useDispatch()

  //states
  const [section, setSection] = useState("");
  const [data, setData] = useState({ userPersonalInfo: {} })

  useEffect(() => {
    const locationArr = location.pathname.split("/");
    setSection(locationArr[locationArr.length - 1]);
  }, [location]);

  const close = () => {
    navigate("/");
  };
  const navigation = (route) => {
    navigate(route);
  };

  //logout
  const logout = () => {
    removeToken();
    dispatch(updateLoginStatus(false))
    navigation("/")
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  //fetch user data
  const fetchUserData = async () => {
    const response = await fetchMe();
    if (response.success) {
      setData(response.user)
    }
  }

  //get payload
  const getPayload = (payloadData, from) => {
    if (from === "overview") {
      const { firstName, lastName, emailAddress, city, profileImageFull, profileImageThumbnail } = payloadData
      data.userPersonalInfo.profileImageFull = profileImageFull
      data.userPersonalInfo.profileImageThumbnail = profileImageThumbnail
      return {
        ...data,
        firstName,
        lastName,
        emailAddress,
        city,
        fullName: firstName + " " + lastName
      }
    }
    if (from === "profile") {
      const { verbs, tellUsAndAskYours, yourStory } = payloadData
      data.userPersonalInfo = { ...data.userPersonalInfo, verbs, tellUsAndAskYours, yourStory }
      return {
        ...data,
      }
    }
  }

  return (
    <div>
      <div className="top__header">
        <div className="heading__section w-50">
          <div onClick={close} className="close__icon">
            <CloseIcon color={theme.purple} />
          </div>
          <div className="center__line"></div>
          <div className="description">Your Account</div>
        </div>
        <div className="w-50 d-flex justify-content-end">
          <button onClick={logout} className="btn p-0 logout__btn">Logout</button>
        </div>
      </div>
      <div className="w-100 tab__container">
        <div className="w-100 tab__section">
          <div
            onClick={() => navigation("/profile/edit/overview")}
            className={`tab ${section === "overview" && "active__tab"}`}
          >
            General
          </div>
          <div
            onClick={() => navigation("/profile/edit/about")}
            className={`tab ${section === "about" && "active__tab"}`}
          >
            Your Profile
          </div>
        </div>
      </div>
      <div className="profile__container">
        {section === "overview" ? (
          <Personal fetchUserData={fetchUserData} getPayload={getPayload} data={data} />
        ) : (
          section === "about" && <Details fetchUserData={fetchUserData} getPayload={getPayload} data={data} />
        )}
      </div>
    </div>
  );
};

export default Login;
