import React from 'react';
import { useSelector } from 'react-redux';

//import components
import { RoundedBtn } from '../../components';

//import css and images
import './hero.scss';
import ringImage from '../../assets/images/svgImages/hero__rings.svg';


const Index = ({ heading, description, image, btnText }) => {

    const { windowWidth } = useSelector(state => state)

    return (
        <div className='hero__main row m-0'>
            {windowWidth < 769 && <div className='col-md-6 hero__image__section d-flex justify-content-end'>
                <img src={ringImage} />
            </div>}
            <div className='col-md-6'>
                <div dangerouslySetInnerHTML={{__html:heading}} className='hero__heading'>

                </div>
                <div dangerouslySetInnerHTML={{__html:description}} className='hero__description' />
                <div>
                    <RoundedBtn bgYellow text={btnText} />
                </div>
            </div>
            {windowWidth > 768 && <div className='col-md-6 hero__image__section d-flex justify-content-end'>
                <img src={image} />
            </div>}
        </div>
    )
}

export default Index