import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  windowWidth: window.innerWidth,
  loginStatus: false,
  events:[],
  connectionReq: [],
  toPrice:false,
  requestsSent:[]
};

const counterSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateData: (state, { payload }) => {
      state.data = payload;
    },
    updateWindowWidth: (state, { payload }) => {
      state.windowWidth = Number(payload);
    },
    updateLoginStatus: (state, { payload }) => {
      state.loginStatus = payload;
    },
    updateEvents: (state, { payload }) => {
      state.events = payload;
    },
    updateConnectionRequests: (state, { payload }) => {
      state.connectionReq = payload;
    },
    updateToPrice: (state, { payload }) => {
      state.toPrice = payload;
    },
    updateRequestSent: (state, { payload }) => {
      console.log("Payload",payload)
      state.requestsSent = payload;
    },
  },
});

export const {
  updateData,
  updateWindowWidth,
  updateLoginStatus,
  updateEvents,
  updateConnectionRequests,
  updateToPrice,
  updateRequestSent
} = counterSlice.actions;
export default counterSlice.reducer;
