import React from "react";

//import component and styles
import BlackButton from "../buttons/BlackButton";
import "./Input.scss";

const ImageUploader = ({
  onchange,
  label,
  classes,
  required,
}) => {
  return (
    <div className={`w-100  ${classes}`}>
      <label className="input__label">
        {label}
        {required && <span>*</span>}
      </label>
      <div className="upload__file">
        <input
          type="file"
          id="myFile"
          onChange={onchange}
          name="filename"
        />
        <BlackButton
          classes={"upload__btn"}
          text={
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <div>+</div>
              <div>Upload</div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ImageUploader;
