import React from 'react'
import { FooterNew, NewNavbar } from '../../components'
import '../PrivacyPolicy/Privacy.scss';
import subFooterPrefix from '../../assets/images/subfooterPrefix.png';

const Index = () => {
  const privacyPolicy = [
    {
      heading: "Introduction: ",
      contents: [
        "The Meetings Company provides a platform for networking, professional development, and exclusive events. Our services are available to members who agree to abide by these Terms of Service and Privacy Policy.",
      ]
    },
    {
      heading: "Definitions:",
      list: [
        " 'Communications' refers to any communication platform users make, including but not limited to posts, comments, and private messages.",
        "'Contribution' refers to any content uploaded or posted by platform users, including but not limited to text, images, and videos.",
        "'Event' refers to any gathering, meeting, or function organized by The Meetings Company.",
        "'Guest' refers to any individual who attends an event but is not a registered member of The Meetings Company.",
        "'Member' refers to any individual who has registered for membership with The Meetings Company.",
        "'Service' refers to any service provided by The Meetings Company, including but not limited to the organization of events, provision of networking opportunities, and access to exclusive content.",
        "'User' refers to anyone who accesses the platform, regardless of membership status.",
        "'Registered User' refers to any individual who has registered an account with The Meetings Company.",
        "'Waitlist' refers to individuals who have expressed interest in attending an event but have yet to be confirmed as attendees.",
        "'Media' refers to exclusive written and visual content produced by The Meetings Company and made available to Members.",
        "'Life Is Meeting Directory' refers to a directory of members that provides double opt-in introductions between members.",
        "Accessing the Website: If you are provided with a username, identification code, password, or any other piece of information as part of our security procedures, you must treat such information as personal and confidential. You must not disclose it to any third party. We have the right to turn off any user identification code or password, whether chosen by you or allocated by us, at any time if, in our reasonable opinion, you have failed to comply with any of the provisions of these terms of use."

      ]
    },
    {
      heading: "Your Account",
      contents: ["To fully access and enjoy the benefits of The Meetings Company, you must register and create an account with us. While you can view the platform without creating an account, membership and the ability to make purchases through our platform require an account setup with a username and password.",
        "Our platform is intended for users over the age of 18. The Meetings Company does not knowingly collect or solicit information from anyone under 18. If we learn that we have inadvertently collected personal information from a person under 18 without parental consent, we will delete that information as quickly as possible.",
        "You are responsible for maintaining the confidentiality of your login details and any activities under your account. It is important for your account's security to keep your login details secure. If you suspect that your account has been compromised or misused in any way, please report it immediately to us at info@lifeismeeting.com.",
        "Your account is personal to you. You may not sell, gift, or transfer your account to another person. Any actions may result in suspension or termination of your account. By creating an account, you agree to these terms and accept responsibility for all activities occurring under your account."
      ]
    },
    {
      heading: "Your Profile",
      contents: [
        "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
        "Your profile will include information you choose to share about yourself, such as your name, professional background, interests, and contact information. You have control over what information you choose to include in your profile, and you can update this information at any time.",
        "Your profile is searchable by other members of The Meetings Company. This allows other members to find you based on shared interests or professional fields. However, to protect your privacy, any contact between members through the directory requires a double opt-in introduction system, ensuring that both parties have consented to the interaction.",
        "Please note that by creating a profile and including information in the 'Life Is Meeting Directory', you agree to share this information with other members of The Meetings Company. You should only include information that you are comfortable sharing with others. If you have any privacy or data security concerns, please contact us at info@lifeismeeting.com.",
        "The Meetings Company reserves the right to remove any content from profiles deemed inappropriate or violating our Terms of Service. This includes but is not limited to, content that is offensive, discriminatory, or infringes on the rights of others."
      ],

    },
    {
      heading: "Membership Benefits: ",
      contents: [
        "Membership to The Meetings Company provides a range of exclusive benefits. Members gain access to various exclusive events, including networking sessions, professional development workshops, and social gatherings. These events are designed to foster connections, enhance professional skills, and provide unique experiences. In addition, membership includes access to the Life Is Meeting directory. This directory is a curated list of members, providing opportunities for networking and collaboration. The directory includes a double opt-in introduction system, ensuring privacy and consent in all interactions. Furthermore, members have access to exclusive media content. This includes written and visual content produced by The Meetings Company, designed to inform, inspire, and engage our members."
      ]
    },
    {
      heading: "Payment:",
      contents: [
        "Membership to The Meetings Company requires the payment of membership fees. These fees can be paid either on a monthly or annual basis, depending on the preference of the member. The payment must be made in full at the beginning of the billing cycle. This ensures uninterrupted access to all the benefits and services provided by The Meetings Company.",
        "The Meetings Company uses Stripe, a third-party payment processor, to handle transactions. Stripe's Terms of Service and Privacy Policy are governed by Stripe's Terms of Service and Privacy Policy. Review these documents before proceeding.",
        "When you make a payment, you will be asked to provide certain information relevant to the transaction, including your credit card number, the expiration date of your credit card, and your billing address. By providing this information, you represent and warrant that you have the legal right to use the payment method provided.",
        "Please note that The Meetings Company does not store any of your credit card information. This information is provided directly to our third-party payment processors whose use of your personal information is governed by their respective privacy policies.",
        "In the event of a failed payment, your membership may be suspended until the payment issue is resolved. If a payment is not successfully settled due to expiration, insufficient funds, or otherwise. In that case, we may suspend your access to the services until we have successfully charged a valid payment method.",
        "All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties. Prices for our services may change at any time at The Meetings Company's discretion. We will provide you with reasonable notice of any such pricing changes by posting the new prices on our services and/or by sending you an email notification. If you do not wish to pay the new prices, you can cancel your membership before the change goes into effect."
      ]
    },
    {
      heading: "Contributions and Communications:",
      contents: [
        "The Meetings Company provides a platform for members to share their views and opinions. However, it is important to note that these views and opinions are those of the individual members and do not reflect the views of The Meetings Company. Members are responsible for the content they submit, and may be held liable for any material that is illegal, offensive, or infringes on the rights of others. We encourage all members to communicate respectfully and responsibly.",
        "The views and opinions expressed in any Contributions or Communications made by members do not reflect those of The Meetings Company. As a member, you may be held liable for any material you submit as a Communication or Contribution, including but not limited to material published on message boards, chat rooms, or any other interactive areas of our services.",
        "Your participation in the Service, including but not limited to any Communications and Contributions, must not breach any State, Federal or international law. Specifically, your Communications and Contributions must not:"
      ],
      list: [
        "Excite or encourage the breach of any such law;",
        "Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;",
        " Deceive, impersonate any person, or misrepresent your identity or affiliation with any person;",
        "Cause annoyance, inconvenience, or needless anxiety;",
        "Invade another's privacy;",
        "Harass, upset, embarrass, alarm, or annoy any other person;",
        "Facilitate or encourage prostitution or pedophilia;",
        "Be pornographic, obscene, profane, vulgar, or defamatory;",
        "Be abusive, offensive, hateful, inflammatory, threatening, or promote violence or be otherwise likely to be harmful to those persons likely to use the Service;",
        "Infringe any rights (including intellectual property rights) of any person, entity, or organization; or",
        "Give the impression that The Meetings Company endorses your actions or Contributions or Communications if this is not the case.",

      ],
      afterListContents: [
        "By submitting a Contribution, you hereby grant The Meetings Company a non-exclusive, worldwide, royalty-free, perpetual, non-revocable license to use, distribute, adapt, modify, display, reproduce, and transmit material subsisting in such Contribution in any and all media in any manner in whole or in part without any duty to account to you. You also grant The Meetings Company the right to permit Users to view and copy such information for personal use.",
        "The Meetings Company reserves the right, but not the responsibility, to remove any Contribution or Content which it deems in its sole discretion to be harmful, offensive, or otherwise in violation of these Terms. This includes, but is not limited to, Contributions or Communications that violate the guidelines outlined in this section"
      ]
    },
    {
      heading: "Ownership of Contributions",
      contents: [
        'As a member of The Meetings Company, you may upload, post, or otherwise contribute content to your account or profile (collectively, "Contributions"). You retain all rights in, and are solely responsible for, the Contributions you post to The Meetings Company.',
        "By submitting Contributions to The Meetings Company, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, display, reproduce, re-post, modify, create derivative works, perform, and distribute your Contributions on The Meetings Company to operate and provide the services to you and to our other users. This license is only to operate and provide our services.",
        "You represent and warrant that you own the Contributions or that you have all rights necessary to grant us a license to use that Contributions as described in these Terms. You also represent and warrant that neither your Contributions, nor your use and provision of your Contributions to be made available through the services, nor any use of your Contributions by The Meetings Company on or through the services will infringe, misappropriate or violate a third party's intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.",
        'Please remember that other users may search for, see, use, modify, and reproduce any of your Contributions that you submit to any "public" area of the services. You should think carefully about what you are making public before contributing Contributions to the services.'
      ]
    },
    {
      heading: "Indemnity:",
      contents: [
        "By using our services, you agree to indemnify and hold harmless The Meetings Company, its employees, representatives, and agents from any claims, actions, demands, or other proceedings arising from using our services. This includes any breach of these Terms of Service, as well as any negligence or breach of duty on your part. This indemnity extends to any costs, damages, or legal fees that may be incurred as a result of such claims or proceedings."
      ]
    },
    {
      heading: "Limitations of Liability and Disclaimer:",
      contents: [
        "To the maximum extent permitted by law, The Meetings Company, its affiliates, directors, employees, agents, or any other party involved in creating, producing, or delivering the services will not be liable for any direct, indirect, incidental, special, exemplary, punitive, or consequential damages, including lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute services, arising out of or in connection with these terms, or from the use of or inability to use the services, or from any communications, interactions, or meetings with other users of the services or other persons with whom you communicate or interact as a result of your use of the services, whether based on warranty, contract, tort (including negligence), product liability, or any other legal theory, and whether or not The Meetings Company has been informed of the possibility of such damage.",
        "To the maximum extent permitted by law, The Meetings Company, its affiliates, directors, employees, agents, or any other party involved in creating, producing, or delivering the services will not be liable for any direct, indirect, incidental, special, exemplary, punitive, or consequential damages, including lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute services, arising out of or in connection with these terms, or from the use of or inability to use the services, or from any communications, interactions, or meetings with other users of the services or other persons with whom you communicate or interact as a result of your use of the services, whether based on warranty, contract, tort (including negligence), product liability, or any other legal theory, and whether or not The Meetings Company has been informed of the possibility of such damage.",
        "The Meetings Company does not guarantee the accuracy, completeness, or usefulness of any information on the services and neither does The Meetings Company adopt nor endorse, nor is The Meetings Company responsible for, the accuracy or reliability of any opinion, advice, or statement made by parties other than The Meetings Company. The Meetings Company takes no responsibility and assumes no liability for any Contributions that you or any other user or third party posts or transmits using our services. You understand and agree that you may be exposed to Contributions that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose.",
        'Your use of the services, including any content or information contained within the services, any site-related services or software that is provided to you, is at your sole risk. The services, its content and any site-related services or software are provided on an "as is" and "as available" basis. The Meetings Company expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.',
        "Nothing in these terms will affect any statutory rights that you cannot contractually agree to alter or waive and are legally always entitled to as a consumer. The laws of certain states or other jurisdictions do not allow limitations on implied warranties, or the exclusion or limitation of certain damages. If these laws apply, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you may have additional rights."
      ]
    },
    {
      heading: "Changes to the Terms",
      contents: [
        "The Meetings Company reserves the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new Terms of Service on this page. Changes to this Terms of Service are effective when they are posted on this page. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms of Service. Please review this Terms of Service periodically for updates or changes.",
      ]
    },
    {
      heading: "Force Majeure",
      contents: [
        'The Meetings Company shall not be considered in breach of or default under these Terms of Service or any contract with you, and shall not be liable to you for any cessation, interruption, or delay in the performance of its obligations hereunder by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of God or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labor strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a "Force Majeure Event").',
      ]
    },
    {
      heading: "Waiver",
      contents: [
        "No waiver of any term of these Terms of Service shall be deemed a further or continuing waiver of such term or any other term, and The Meetings Company's failure to assert any right or provision under these Terms of Service shall not constitute a waiver of such right or provision.",
      ]
    },
    {
      heading: "Severability",
      contents: [
        "If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid. In that case, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms of Service remain in full force and effect.",
      ]
    },
    {
      heading: "Entire Agreement",
      contents: [
        "These Terms of Service constitute the entire agreement between you and The Meetings Company and govern your use of the Service, superseding any prior agreements between you and The Meetings Company with respect to the Service.",
      ]
    },
    {
      heading: "Jurisdiction and Applicable Law: ",
      contents: [
        "These Terms of Service are governed by and shall be construed in accordance with the laws of the State of New York. By using our services, you agree that any dispute arising under these Terms or out of the use of our services will be subject to the exclusive jurisdiction of the State or Federal courts located in the Borough of Manhattan, New York. By agreeing to these Terms, you are submitting to this jurisdiction and waiving any rights to object to such jurisdiction or to claim that it is an inconvenient forum.",
      ]
    },
  ]

  return (
    <div className='privacy__main'>
      <NewNavbar />
      <div className='other__sections'>
        <div className='heading privacy__heading'>Terms of Service for The Meetings Company</div>
        <div className='content__text'>
          <div>
            Welcome to The Meetings Company, a private membership club and networking society. By using our services, you agree to these terms. Please read them carefully.
          </div>
          {privacyPolicy?.map((policy, index) => {
            return <div key={index}>
              <div className='sub__heading'>
                {policy.heading}
              </div>
              {policy?.contents && policy?.contents?.map((v, i) => <div key={i}>{v}</div>)}
              {policy.list && <ul>
                {policy.list.map((v, i) => <li key={i}>{v}</li>)}
              </ul>}
              {policy.afterListContents && policy.afterListContents?.map((v, i) => <div key={i}>{v}</div>)}
            </div>
          })}
        </div>
      </div>
      <FooterNew  title={`Stay in the loop with us as our network <span>grows.<img src=${subFooterPrefix} /></span>`}  />
    </div>
  )
}

export default Index