import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//import components and functions
import ImageBox from "./components/ImageBox.js";
import { Navbar, Spinner, Notification } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAllUsers, verifyMagicLink } from "../../api/api";
import { getInitialScreen } from "../../functions/common";
import logo from '../../assets/images/svgImages/logo_opacity.svg';

//import styles and variables
import "./Members.scss";
import { addToken } from "../../functions/localstorage.js";
import { updateLoginStatus } from "../../redux/reducers/appreducer.js";
import { theme } from "../../constants/data.js";

const Members = () => {

  // refs
  const boxRef = useRef(null);
  const boxRefUpper = useRef(null);
  const notificationRef = useRef();

  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { loginStatus } = useSelector((state) => state);

  //states
  const [boxCount, setBoxCount] = useState(0);
  const [boxCountUpper, setBoxCountUpper] = useState(0);
  const [size, setSize] = useState(getInitialScreen);
  const [sizeUpper, setSizeUpper] = useState({ height: "100%", width: "100%" });
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [called, setCalled] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const verifyToken = async () => {
      setIsLoading(true);
      const token = location.search.replace("?", "");
      const response = await verifyMagicLink({ token });
      navigate("/", { replace: true })
      setIsLoading(false);
      if (response?.success) {
        addToken(response.token);
        dispatch(updateLoginStatus(true));
        navigate("/");
      } else {
        navigate("/link-expired");
      }
    };

    if (location.search !== "") {
      setCalled(cal => {
        if (!cal) {
          verifyToken();
        }
        return true
      })
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      const newWidth = Math.floor(width / 20) * 20;
      const newHeight = Math.floor(height / 20) * 20;
      const newBoxCount =
        Math.floor(newWidth / 20) * Math.floor(newHeight / 20);
      setSize({ height: newHeight, width: newWidth });
      setBoxCount(newBoxCount);
    });

    const resizeObserverUpper = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      const newWidthUpper = Math.floor(width / 20) * 20;
      const newHeightUpper = Math.floor(height / 20) * 20;
      const newBoxCountUpper =
        Math.floor(newWidthUpper / 20) * Math.floor(newHeightUpper / 20);
      setSizeUpper({ height: newHeightUpper, width: newWidthUpper });
      setBoxCountUpper(newBoxCountUpper);
    });


    const box = boxRef.current;
    const boxUpper = boxRefUpper.current;
    resizeObserver.observe(box);
    resizeObserverUpper.observe(boxUpper);

    fetchUsers();

    return () => {
      resizeObserver.unobserve(box);
      resizeObserverUpper.unobserve(boxUpper);
    };
  }, [location]);

  // on user profile image click
  const onProfileClick = (link) => {
    if (!link?.includes("https://") && !link?.includes("http://")) {
      window.open(link, "_blank");
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };

  //fetch all users
  const fetchUsers = async () => {
    const users = await fetchAllUsers() ?? []
    const sorted = users?.sort(function (a, b) {
      const nameA = a.firstName.toUpperCase() + " " + a.lastName.toUpperCase();
      const nameB = b.firstName.toUpperCase() + " " + b.lastName.toUpperCase();
      const isNameAAlphabetic = /^[a-zA-Z]/.test(nameA);
      const isNameBAlphabetic = /^[a-zA-Z]/.test(nameB);
      if (isNameAAlphabetic && !isNameBAlphabetic) {
        return -1;
      }
      if (!isNameAAlphabetic && isNameBAlphabetic) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setUsers(sorted);
  };

  return (
    <div className="home__main">
      {isLoading && (
        <div className="loadingContainer">
          <Spinner color={theme.white} size={50} />
        </div>
      )}
      <Navbar home />
      <div className="box__main__container" ref={boxRef}>
        <div
          style={{ width: size.width, height: size.height }}
          className="d-flex flex-wrap box__container"
        >
          {Array.from({ length: boxCount }).map((_, index) => (
            <ImageBox
              onProfileClick={onProfileClick}
              data={[]}
              index={index}
              key={index}
            />
          ))}
        </div>
        <img className="logo__image" src="./images/logo.png" alt="logo" />
      </div>
      <div className="box__main__container__upper" ref={boxRefUpper}>
        <div
          style={{ width: sizeUpper.width, height: sizeUpper.height }}
          className="d-flex flex-wrap box__container"
        >
          {Array.from({ length: boxCountUpper }).map((_, index) => (
            <ImageBox
              onProfileClick={onProfileClick}
              data={users}
              index={index}
              key={index}
            />
          ))}
        </div>
        <img className="logo__image" src={logo} alt="logo" />
      </div>
      <Notification notificationRef={notificationRef} />
    </div>
  );
};

export default Members;
