import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

//import functions
import {
  updateLoginStatus,
  updateWindowWidth,
} from "../redux/reducers/appreducer";
import { getToken } from "../functions/localstorage";
import { changeTitle } from "../functions/common";
import { routes } from "./routes";


//scroll to top when screen changes
function ScrollToTop({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    changeTitle(location)
  }, [location]);

  return children;
}

export const Router = () => {
  const dispatch = useDispatch();
  const token = getToken();

  useEffect(() => {
    token && dispatch(updateLoginStatus(true));
    //window resize event
    const handleResize = () => {
      dispatch(updateWindowWidth(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [token]);

  //private route
  const PrivateRoute = ({ children }) => {
    const token = getToken();
    return token ? children : <Navigate to="/" />;
  };

  // can not go to auth screens if already logged in
  const HomeRouteOnLogin = ({ children }) => {
    const token = getToken();
    return !token ? children : <Navigate to="/" />;
  };


  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {routes.map(({ path, element, onlyLoggedIn, onlyLoggedOut }, index) => {
            return <Route key={index} path={path} element={onlyLoggedIn ? <PrivateRoute children={element} /> : onlyLoggedOut ? <HomeRouteOnLogin children={element} /> : element} />
          })}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
