import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//import components and functions
import { FooterNew, NewNavbar, Spinner } from "../../components";
import { HeroSection, Review, SubHero } from "../../layouts";
import Updates from "./components/Updates";
import IntroCardsSection from "./components/IntroCardsSection";
import MemberPricing from "./components/MemberPricing";
import SliderSection from "./components/SliderSection";
import { verifyMagicLink } from "../../api/api";
import { theme } from "../../constants/data";
import { addToken } from "../../functions/localstorage";
import { updateLoginStatus, updateToPrice } from "../../redux/reducers/appreducer";

//import styles and images
import ringImage from "../../assets/images/svgImages/hero__rings.svg";
import subFooterPrefix from '../../assets/images/subfooterPrefix.png';
import "./HomeNew.scss";

const Index = () => {
  const pricingRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false)
  const [called, setCalled] = useState(false);
  const navigate = useNavigate();
  const { toPrice } = useSelector(state => state)


  useEffect(() => {
    setTimeout(() => {
      if (toPrice) {
        pricingRef.current.scrollIntoView({ behavior: "smooth" });
        dispatch(updateToPrice(false))
      }
    }, 1000)
  }, [location]);

  return (
    <div className="home__new__main">
      {isLoading && (
        <div className="loadingContainer">
          <Spinner color={theme.white} size={50} />
        </div>
      )}
      <NewNavbar pricingRef={pricingRef} />
      <div className="other__section">
        <HeroSection
          image={ringImage}
          heading={"PLAY GRACEFULLY WITH <span>ideas</span>"}
          description="We are a <span>new kind of social network</span> of people who don’t all think, look, or sound the same. We meet <span>in person</span> and <span>online</span> to explore ideas and increase trust."
          btnText="Join Now"
        />
        <SubHero
          heading={"What happens when we stop meeting? A collapse in trust."}
          description={
            "This is why we are building the <span>modern campfire</span> experience in people's homes all over the world. If we succeed, perhaps we can help solve the cycle of distrust, truth decay, and the epidemic of loneliness."
          }
        />
        <Updates />
      </div>
      <IntroCardsSection />
      <SliderSection />
      <div className="other__section">
        <MemberPricing pricingRef={pricingRef} />
        <Review
          description="People who don’t think, look, or sound like you are waiting to play gracefully with ideas with you."
        />
      </div>
      <FooterNew title={`Stay in the loop with us as our network <span>grows.<img src=${subFooterPrefix} /></span>`} />
    </div>
  );
};

export default Index;
