import { InstagramNew, TwitterNew } from "../assets/Icons";


export const websiteName = "Life is meeting";


export const theme = {
  black: "#000",
  purple: "#1A1F36",
  white: "#FFF",
  darkBlue: "#25253F",
};

export const searchByDropdown = [
  {
    name: "Search by Name",
    value: "name",
  },
  {
    name: "Filter by Meeting",
    value: "meeting",
  },
  {
    name: "Search by Verbs",
    value: "verb",
  },
];

export const links = {
  linkdin: "https://www.linkedin.com/company/lifeismeeting/",
  media: "https://lifeismeeting.substack.com/",
  meetings: "https://lifeismeeting.substack.com/p/meeting-spring",
  contact: "meeting@lifeismeeting.com",
  gmailLink: "https://mail.google.com/mail/u/0/#inbox",
  footerMeetings: "https://www.eventbrite.com/cc/meetings-2382809?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=odclsxcollection&utm-source=cp&aff=odclsxcollection",
  instagram: "https://www.instagram.com/lifeismeeting/",
  facebook: "https://www.facebook.com/lifeismeeting/",
  twitter: "https://twitter.com/lifeismeeting",
  contact: "mailto: info@lifeismeeting.com",
  foundingMembers: "https://airtable.com/app4UFWevWkt3wc4A/shr1u9DP15K5kp68D",
  eventCreators: "https://airtable.com/app4UFWevWkt3wc4A/shrAKwpOr6Fcgcbyu",
  host: "https://airtable.com/app4UFWevWkt3wc4A/shrvutLJCJZzpWv58",
  moderators: "https://airtable.com/app4UFWevWkt3wc4A/shrsVRCzk15HV30IJ",
  loneliness: "https://fee.org/articles/harvard-study-an-epidemic-of-loneliness-is-spreading-across-america/?gclid=CjwKCAjwkeqkBhAnEiwA5U-uM4QAVzsGJF46LVOLr4XvgIEYgoHuZDsF7BilaLrVz_R2ld1VyaTOPxoCle0QAvD_BwE&utm_source=substack&utm_medium=email",
  truthDecay: "https://www.rand.org/research/projects/truth-decay.html?utm_source=substack&utm_medium=email",
  distrustCycle: "https://www.edelman.com/sites/g/files/aatuss191/files/2022-01/2022%20Edelman%20Trust%20Barometer%20FINAL_Jan25.pdf",
  education: "https://substack.com/top/education"

};

export const linksWithIcons = [
  {
    name: "Instagram",
    link: links.instagram,
    Icon: InstagramNew,
  },
  {
    name: "Twitter",
    link: links.twitter,
    Icon: TwitterNew,
  },
]
export const texts = {
  verb: "Which verbs define you now?",
  notSearchDescription: "Start searching and you’ll start to see community members who you can connect with.",
  noSearchResultDescription: "Unfortunately right now we don’t have anything that matches what your searched. Please try and search something else.",
  notSearchTitle: "Search the community.",
  noSearchResultTitle: (value) => `No search results for <span>“${value}”</span>`,
}

export const testimonials = [
  {
    message: "“I recently attended multiple meetings that utilized Alby's moderation services and have been thoroughly impressed. His ability to seamlessly navigate and moderate discussions across a diverse range of topics is unparalleled. Alby's keen listening skills, tactful interjections, and insightful contributions fostered engaging, well-structured, and productive conversations. His adeptness at maintaining a balanced and respectful dialogue, especially during complex discourse, is commendable. I highly recommend Alby for anyone looking to elevate the quality and effectiveness of their group discussions or forums.”",
    name: "Neil",
    position: "Investment Banker"
  },
  {
    message: "“I have enjoyed every second of each Life is Meeting event I have been to thus far”",
    name: "Sarah",
    position: "Head of PR"
  },
  {
    message: "“Such a remarkable and insightful way of asking questions”",
    name: "Ryan",
    position: "a US Marine"
  },
  {
    message: "“Alby's moderation definitely improved our company culture”",
    name: "CEO",
    position: "Architecture company"
  }
]

export const meetings = [
  {
    location: "The Chocolate Factory - 54 Elizabeth St Red Hook Red Hook, NY 12571",
    title: "How do you think about courage? (Life is Meeting)",
    date: "27",
    month: "January",
    time: "7PM EDT",
    link: "https://www.eventbrite.com/e/how-do-you-think-about-courage-life-is-meeting-tickets-801439556287?aff=oddtdtcreator&utm_medium=email&utm_source=substack",
    exactDate:"2024-01-27"
  },
  {
    location: "Rachel's place - 200 East 90th St New York NYC, NY 10128",
    title: "How do you think about hope? (Life is Meeting)",
    date: "1",
    month: "Feburary",
    time: "7PM EDT",
    link: "https://substack.com/redirect/28a8284a-6714-4371-85cc-aabc00034372?j=eyJ1IjoiMnNqOHUyIn0.QffPwmANOlk5y8mEr2fgAjgNmzmlIieb5QTgTCHiACQ",
    exactDate:"2024-02-01"
  },
  {
    location: "Gillian's place - 53 East 80th Street New York, NY 10075",
    title: "How do you think about 2024? (Life is Meeting)",
    date: "8",
    month: "Feburary",
    time: "7PM EDT",
    link: "https://substack.com/redirect/3ec4e2f5-4502-4929-98bb-0e341787fbec?j=eyJ1IjoiMnNqOHUyIn0.QffPwmANOlk5y8mEr2fgAjgNmzmlIieb5QTgTCHiACQ",
    exactDate:"2024-02-08"
  },
  {
    location: "Beverly Glen Boulevard - Beverly Glen Boulevard Los Angeles, CA 90077",
    title: "How do you think about happiness? (Life is Meeting)",
    date: "15",
    month: "Feburary",
    time: "7PM EDT",
    link: "https://substack.com/redirect/b7f2bd84-6700-45cb-86d2-6738e064d96e?j=eyJ1IjoiMnNqOHUyIn0.QffPwmANOlk5y8mEr2fgAjgNmzmlIieb5QTgTCHiACQ",
    exactDate:"2024-02-15"
  },
  {
    location: "Wilshire Blvd Los Angeles, CA 11301 United States",
    title: "How do you think about gender? (Life is Meeting)",
    date: "22",
    month: "Feburary",
    time: "7PM EDT",
    link: "https://www.eventbrite.com/e/how-do-you-think-about-gender-life-is-meeting-tickets-814530993127?aff=oddtdtcreator",
    exactDate:"2024-02-22"
  },
  {
    location: "Atlantic Avenue Brooklyn New York, NY 11217 United States",
    title: "How do you think about being human in the age of Ai? (Life is Meeting)",
    date: "5",
    month: "March",
    time: "7PM EDT",
    link: "https://www.eventbrite.com/e/how-do-you-think-about-being-human-in-the-age-of-ai-life-is-meeting-tickets-814529588927?aff=oddtdtcreator",
    exactDate:"2024-03-05"
  },
  {
    location: "Wittenberg road Woodstock New York, NY 12409 United States",
    title: "How do you think about health? (Life is Meeting)",
    date: "14",
    month: "March",
    time: "7PM EDT",
    link: "https://www.eventbrite.com/e/how-do-you-think-about-health-life-is-meeting-tickets-814527372297?aff=oddtdtcreator",
    exactDate:"2024-03-14"
  },
]
