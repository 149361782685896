import React from 'react'

const RoundedBtn = ({ text, onClick, bgYellow, classes }) => {
    return (
        <button onClick={onClick} className={`outlined__btn ${bgYellow ? "bg__yellow":""} ${classes && classes}`}>
            {text}
        </button>
    )
}

export default RoundedBtn;