import { validateEmail } from "./common";

// edit profile overview validations
export const getOverviewValidtions = (data, file) => {
  const { firstName, lastName, city, emailAddress , profileImageThumbnail,profileImageFull } = data;
  let success = true;
  if (firstName === "" || !firstName) {
    success = false;
  }
  if (lastName === "" || !lastName) {
    success = false;
  }
  if (city === "" || !city) {
    success = false;
  }
  if (emailAddress === "" || !emailAddress) {
    success = false;
  } else if (!validateEmail(emailAddress)) {
    success = false;
  }
  if (!file && !profileImageFull && !profileImageThumbnail) {
    success = false;
  }
  return success;
};

// edit profile about validations
export const getProfileValidtions = (data) => {
  const { verbs, tellUsAndAskYours, yourStory } = data;
  let success = true;
  if (tellUsAndAskYours === "" || !tellUsAndAskYours) {
    success = false;
  }
  if (yourStory === "" || !yourStory) {
    success = false;
  }
  for(var i = 0; i < verbs.length ; i++){
    if(verbs[i] === "" | !verbs[i]){
        success = false
    }
  }
  return success;
};
