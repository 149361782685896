import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./IntroCarousel.scss";

// import required modules
import { Navigation, A11y } from "swiper/modules";
import { useEffect } from "react";

const Swipper = ({ items, sliderClasses }) => {

  return (
    <>
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={20}
        slidesPerView={1}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
        }}
        pagination={{ clickable: true }}
      >
        {items?.map((v, i) => (
          <SwiperSlide key={i}>
            <div className={`intro__card__main ${sliderClasses ? sliderClasses : ""}` }>
              {v.image && <img className="review__img" src={v.image} />}
              <div className="review__section__new">
                <div className="heading">{v.heading}</div>
                <div className="description">{v.description}</div>
                <div className="list__section">
                  {v.list?.map((item, index) => (
                    <div className="d-flex gap-2 list__" key={index}>
                      <div><div style={{backgroundColor:v.bulletColor}} className="bullet" /></div>
                      <div className="list__text">{item}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Swipper;
