import React from "react";
import {  NewCarousel, RoundedBtn } from "../../../components";
import { useState } from "react";
import {
  networkMemberCarousel,
  foundingMemberCarouselData,
} from "../../../constants/carouselData";
import { Switch } from "antd";
import { useEffect } from "react";

const SliderSection = () => {
  const [active, setActive] = useState("Members");
  const [isLoading, setIsLoading] = useState(false);

  const changeActive = (tab) => {
    if (tab !== active) {
      setIsLoading(true);
      setActive((t)=> t === "Members" ? "Creators":"Members");
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
    const targetElement = document.querySelector(".ant-switch-handle"); // Replace 'yourElementId' with the actual element ID
    if (targetElement.classList.contains("member")) {
      targetElement.classList.add("creator");
      targetElement.classList.remove("member");
    } else {
      targetElement.classList.add("member");
      targetElement.classList.remove("creator");
    }
  };

  useEffect(() => {
    const targetElement = document.querySelector(".ant-switch-handle"); // Replace 'yourElementId' with the actual element ID
    targetElement.classList.add("member");
  }, []);

  console.log("Active",active)

  return (
    <div className="slider__section">
      <div className="heading__text"><span>Meet Our</span> Network</div>
      <div className="d-flex switch__custom d-flex justify-content-center">
        <div className="d-flex justify-content-center">
          <div className="button__main__section">
          <Switch
              onChange={changeActive}
              className="switch__new"
              checkedChildren={"Members"}
              unCheckedChildren={"Creators"}
              defaultChecked={false}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        {!isLoading ? (
          active === "Members" ? (
            <NewCarousel items={networkMemberCarousel} />
          ) : (
            <NewCarousel items={foundingMemberCarouselData} />
          )
        ) : null}
      </div>
    </div>
  );
};

export default SliderSection;
