import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//import components and functions
import {
  Spinner,
  FooterNew,
  RoundedBtn,
} from "../../components";
import { Mainlayout } from "../../layouts";
import { fetchUserDetails, sendConnectRequest, updateConnectRequest } from "../../api/api";

//import styles and variables
import { texts, theme } from "../../constants/data";
import "./ProfileDetail.scss";
import { useSelector } from "react-redux";
import networkPrefix from "../../assets/images/networkPrefix.png";
import meetingPrefix from "../../assets/images/meetingPrefix.png";
import subFooterPrefix from "../../assets/images/subfooterPrefix.png";
import mediaPrefix from "../../assets/images/mediaPrefix.png";

const ProfileDetails = () => {
  const params = useParams();

  // states
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({});
  const [connectionStatus, setConnectionState] = useState(false);
  const [isMe, setIsMe] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [deletebtnLoading, setDeletebtnLoading] = useState(false);
  const connectionRef = useRef();
  const { connectionReq } = useSelector((state) => state);

  //connection request send function
  const onConnect = async () => {
    setbtnLoading(true)
    await sendConnectRequest({ recipientId: params?.id });
    await fetchUser();
    setbtnLoading(false)
  };

  //connection request accept or reject function
  const updateRequest = async (status) => {
    const filtered = connectionReq?.filter((req) => req?._id === connectionStatus?.connection?._id)
    if (filtered.length > 0) {
      status === "rejected" ? setDeletebtnLoading(true) : setbtnLoading(true)
      await updateConnectRequest({ connectionStatus: status, id: connectionStatus?.connection?._id });
      connectionRef.current.fetchConnections()
      await fetchUser();
      setDeletebtnLoading(false)
      setbtnLoading(false)
    } else {
      await fetchUser();
    }
  }

  //fetch user details using id
  const fetchUser = async () => {
    const id = params?.id;
    const response = await fetchUserDetails(id);
    setUser(response?.userData);
    setConnectionState(response?.connectionStatus);
    setIsMe(response?.isMe)
  };

  useEffect(() => {
    document.title = "Life is Meeting"
    const fetchUser = async (loading) => {
      loading && setIsLoading(true)
      const id = params?.id;
      const response = await fetchUserDetails(id);
      setUser(response?.userData);
      setConnectionState(response?.connectionStatus);
      setIsLoading(false)
      setIsMe(response?.isMe)
      document.title = `${response?.userData?.firstName} ${response?.userData?.lastName} | Life is Meeting`
    };
    fetchUser(true);
  }, [params]);

  const Button =
    connectionStatus?.requestStatus === 0 ? (
      <RoundedBtn
        text="Connection Requested"
        gray={true}
        disabled
        bgYellow={true}
      />
    ) : connectionStatus?.requestStatus === 1 ? (
      <div className="d-flex">
        <RoundedBtn
          text={
            btnLoading ? (
              <Spinner size={15} color={theme.white} />
            ) : (
              "Confirm Connection"
            )
          }
          bgYellow={true}
          onClick={() => updateRequest("accepted")}
          disabled={btnLoading}
        />
        <button
          disabled={btnLoading}
          className="decline_btn"
          onClick={() => updateRequest("rejected")}
        >
          {deletebtnLoading ? (
            <Spinner size={15} color={theme.white} />
          ) : (
            "Decline Connection"
          )}
        </button>
      </div>
    ) : (
      <RoundedBtn
        text={
          btnLoading ? <Spinner size={15} color={theme.white} /> : "Connect"
        }
        bgYellow={true}
        onClick={()=> onConnect()}
        disabled={btnLoading}
      />
    );

  const facts = [
    `I’ve attended <span class="font_italic">${user?.eventAttended?.length}</span> meeting${
      user?.eventAttended?.length === 0 || user?.eventAttended?.length > 1 ? "s" : ""
    }`,
    `I’m currently based in <span class="font_italic">${user?.city}</span>`,
    `I can be reached at <span class="font_italic"><a class="email_underline" href='mailto:${user?.emailAddress}'>${user?.emailAddress}</a></span>`,
  ];

  return (
    <>
      <Mainlayout
        connectionRef={connectionRef}
        layoutSize={true}
        greyBgColor={true}
      >
        {isLoading ? (
          <div className="spinner__container">
            <Spinner color={theme.black} size={36} />
          </div>
        ) : connectionStatus?.isConnected || isMe ? (
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-4">
              <div className="img__box">
                <img src={user?.userPersonalInfo?.profileImageFull} className="img__width" />
              </div>
              <div className="text__section d-flex">
                <div className="col-12">
                  <div className="user_name">{`${user?.firstName} ${user?.lastName}`}</div>
                  <div className="user_des">
                    {user?.userPersonalInfo?.tellUsAndAskYours}
                  </div>
                  {!isMe && (
                    <div className="mt-3">
                      <RoundedBtn
                        text={
                          btnLoading ? (
                            <Spinner size={15} color={theme.white} />
                          ) : (
                            "Connected"
                          )
                        }
                        bgYellow={true}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5 facts__container">
                <img src={networkPrefix} className="mb-3" />
                <div className="section__heading mt-3">QuickFacts</div>
                <div className="mt-4">
                  {facts?.map((item, index) => {
                    return (
                      <div key={index} className="list_">
                        <div className="bullet" />
                        <div className="bullet__point" dangerouslySetInnerHTML={{__html: item}}></div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5 facts__container">
                <img src={meetingPrefix} className="mb-3" />
                <div className="section__heading mt-2">
                  Which verbs define you now?
                </div>
                <div className="mt-4">
                  {user?.userPersonalInfo?.verbs?.map((item, index) => {
                    return (
                      <div key={index} className="list_">
                        <div
                          style={{ backgroundColor: "#FC6D51" }}
                          className="bullet"
                        />
                        <div className="bullet__point">{item}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5 mb-5 facts__container">
                <img src={mediaPrefix} className="mb-3" />
                <div className="mt-3 media_content">
                  {user?.userPersonalInfo?.yourStory}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-4">
              <div className="img__box">
                <img src={user?.userPersonalInfo?.profileImageFull} className="img__width" />
              </div>
              <div className="text__section d-flex">
                <div className="col-12">
                  <div className="user_name">{`${user?.firstName} ${user?.lastName}`}</div>
                  <div className="mt-4">{Button}</div>
                </div>
              </div>
              <div className="mt-5 facts__container">
                <img src={networkPrefix} className="mb-3" />
                <div className="section__heading mt-3">QuickFacts</div>
                <div className="mt-4">
                  <div className="list_">
                    <div className="bullet" />
                    <div className="bullet__point">I’m currently based in <span className="font_italic">{user?.city}</span></div>
                  </div>
                </div>
              </div>
              <div className="mt-5 facts__container">
                <img src={meetingPrefix} className="mb-3" />
                <div className="section__heading mt-2">
                  Which verbs define you now?
                </div>
                <div className="mt-4 mb-4">
                  {user?.userPersonalInfo?.verbs?.map((item, index) => {
                    return (
                      <div key={index} className="list_">
                        <div
                          style={{ backgroundColor: "#FC6D51" }}
                          className="bullet"
                        />
                        <div className="bullet__point">{item}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </Mainlayout>
      <FooterNew
        title={`Stay in the loop with us as our network <span>grows.<img src=${subFooterPrefix} /></span>`}
      />
    </>
  );
};

export default ProfileDetails;
