import { InstagramIcon, InstagramNew, TwitterIcon, TwitterNew } from '../assets/Icons'
import { links } from './data'

export const features = ["News and updates", "Free ideas and articles", "No bad vibes"]
export const community = [
    {
        text: "Members",
        link: "/"
    },
    {
        text: "Founding Members",
        link: links.foundingMembers,
        newTab: true
    },
    {
        text: "Event Creators",
        link: links.eventCreators,
        newTab: true
    },
    {
        text: "Host",
        link: links.host,
        newTab: true
    },
    {
        text: "Moderators",
        link: links.moderators,
        newTab: true
    },
]
export const company = [
    {
        text: "Media",
        link: links.media,
        newTab: true
    },
    {
        text: "Meetings",
        link: links.footerMeetings,
        newTab: true
    },
    {
        text: "Jobs",
        link: links.linkdin,
        newTab: true
    },
    {
        text: "Contact",
        link: links.contact,
        mailTo: true
    },
]
export const policy = [
    {
        text: "Privacy Policy",
        link: "/privacy-policy"
    },
    {
        text: "Terms of Use",
        link: "/terms-and-conditions"
    }
]
export const social = [
    {
        text: "Instagram",
        Icon: InstagramIcon,
        link: links.instagram,
        newTab: true
    },
    {
        text: "Twitter",
        Icon: TwitterIcon,
        link: links.twitter,
        newTab: true
    },
]
export const socialNew = [
    {
        text: "Twitter",
        Icon: TwitterNew,
        link: links.twitter,
        newTab: true
    },
    {
        text: "Instagram",
        Icon: InstagramNew,
        link: links.instagram,
        newTab: true
    }
]