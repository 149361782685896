import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const API_VERSION = "/api/v1" 
const API_URL = BASE_URL+API_VERSION;
export const CLOUDINARY_API = process.env.REACT_APP_CLOUDINARY_API;
export const CLOUD_NAME = process.env.REACT_APP_CLOUDNAME;


export const SERVER_AXIOS = axios.create({
  baseURL: API_URL,
});

