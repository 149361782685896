//add token
export const addToken = (token) => {
  localStorage.setItem("lim__token", token);
};

//get token
export const getToken = () => localStorage.getItem("lim__token");

//remove token
export const removeToken = () => {
  localStorage.removeItem("lim__token");
};

//add email
export const addEmail = (email) => {
  sessionStorage.setItem("lim__email", email);
};

//add email
export const getEmail = () => sessionStorage.getItem("lim__email");