import React, { useEffect } from "react";
import { NewCarousel, RoundedBtn } from "../../../components";
import { useState } from "react";
import {
  networkMemberCarousel,
  foundingMemberCarouselData,
} from "../../../constants/carouselData";
import { Switch } from "antd";

const SliderSection = () => {
  const [active, setActive] = useState("Members");
  const [isLoading, setIsLoading] = useState(false);

  const changeActive = (tab) => {
    setIsLoading(true);
    setActive((act) => (act === "Members" ? "Creators" : "Members"));
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
    const targetElement = document.querySelector(".ant-switch-handle"); // Replace 'yourElementId' with the actual element ID
    if (targetElement.classList.contains("member")) {
      targetElement.classList.add("creator");
      targetElement.classList.remove("member");
    } else {
      targetElement.classList.add("member");
      targetElement.classList.remove("creator");
    }
  };

  useEffect(() => {
    const targetElement = document.querySelector(".ant-switch-handle"); // Replace 'yourElementId' with the actual element ID
    targetElement.classList.add("member");
  }, []);

  return (
    <div className="slider__section">
      <div className="heading__text">
        <span>Meet Our</span> Network
      </div>
      <div className="d-flex switch__custom d-flex justify-content-center">
        {/* <div className="d-flex justify-content-center">
          <div className="button__main__section">
            <RoundedBtn
              onClick={() => changeActive("Members")}
              text="Members"
              classes={active === "Members" ? "bg__yellow" : "no_bg_no_border"}
            />
            <RoundedBtn
              onClick={() => changeActive("Creators")}
              text="Creators"
              classes={active === "Creators" ? "bg__yellow" : "no_bg_no_border"}
            />
          </div>
        </div> */}
        <div className="d-flex justify-content-center">
          <div className="button__main__section">
            <Switch
              onChange={changeActive}
              className="switch__new"
              checkedChildren={"Members"}
              unCheckedChildren={"Creators"}
              defaultChecked={false}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        {!isLoading ? (
          active === "Members" ? (
            <NewCarousel items={networkMemberCarousel} />
          ) : (
            <NewCarousel items={foundingMemberCarouselData} />
          )
        ) : null}
      </div>
    </div>
  );
};

export default SliderSection;
