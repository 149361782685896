//import data from dependencies
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConnectionRequests from "../../drawer/ConnectionRequests";
import { useDispatch, useSelector } from "react-redux";

//import components and functions
import { links, linksWithIcons, theme } from "../../../constants/data";
import { ButtonWithoutOutline, NavbarDrawer, SearchDrawer } from "../../index";
import { BellIcon, MenuIconNew, SearchIcon, UserIcon } from "../../../assets/Icons";
import { updateConnectionRequests, updateLoginStatus, updateRequestSent } from "../../../redux/reducers/appreducer";
import { getConnectionRequests } from "../../../api/api";

//import styles and assets
import logo from "../../../assets/images/svgImages/lim_logo_light.svg";
import logoDark from "../../../assets/images/svgImages/lim_logo.svg";
import "./Navbar.scss";

const NavbarCustom = ({ home, connectionRef, noAbsolute }) => {

  //hooks
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //refs
  const menuRef = useRef();
  const menuBtnRef = useRef();

  const { windowWidth, loginStatus, connectionReq } = useSelector((state) => state);
  const { white, darkBlue } = theme
  const color = home ? white : darkBlue

  //states
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false)

  const login = (toggle) => {
    navigation("/login");
    toggle && setOpenNavbar(false);
  };

  const connectionDrawer = () => {
    setOpenDrawer((dr) => !dr);
  };
  const searchDrawerToggle = () => {
    setOpenSearchDrawer((dr) => !dr);
  };

  const toggleNavbar = () => {
    setOpenNavbar((dr) => !dr);
  };

  const navigation = (route) => {
    navigate(route);
  };

  const userClick = (width) => {
    loginStatus ? width < 769 ? navigation("/profile/edit/overview") : setMenuOpen(m => !m) : location.pathname !== "/login" && navigation("/login");
  }

  const logout = () => {
    localStorage.clear()
    location.pathname !== "/" && navigation("/")
    dispatch(updateLoginStatus(false))
  }

  //fetch connection requests
  const fetchConnections = async () => {
    const response = await getConnectionRequests()
    response?.success && dispatch(updateConnectionRequests(response?.requests))
    response?.success && dispatch(updateRequestSent(response?.requestSent))
  }

  const goTohome = () => {
    if (location.pathname !== "/") {
      const loginPaths = ["/login", "/link-expired", "/login-link"]
      if (loginPaths?.includes(location.pathname)) {
        navigation("/")
      } else {
        navigation("/")
      }
    }
  }

  useEffect(() => {
    fetchConnections()
    if (connectionRef) connectionRef.current = { fetchConnections };
    function handler(event) {
      if (!menuRef.current?.contains(event.target) && !menuBtnRef.current?.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const navLinks = [
    {
      name: "Home",
      link: "/",
      newTab: false
    },
    {
      name: "About",
      link: "/about",
      newTab: false
    },
    {
      name: "Media",
      link: links.media,
      newTab: true
    },
    {
      name: "Meetings",
      link: links.meetings,
      newTab: true
    },
    {
      name: "Moderation",
      link: "/moderation",
      newTab: false
    },
  ]


  return (
    <div>
      <div className={`w-100 navbar__main ${home ? !noAbsolute ? "position-absolute" : "" : "light"}`}>
        <div className="nav__border__section">
          <div className="menu__icon pointer">
            <button onClick={toggleNavbar}>
              <MenuIconNew color={home ? "white" : undefined} />
            </button>
          </div>
          <div className="logo__section col-md-3 p-0 m-0 d-flex align-items-center">
            <img className="pointer" onClick={goTohome} src={home ? logo : logoDark} />
          </div>
          <div className="link__section d-flex col-md-6 p-0 m-0">
            {navLinks.map((v, i) => {
              return v.link === "/" ? <div key={i}>
                <button className="" onClick={goTohome}>
                  {v.name}
                </button>
              </div> : <div key={i}>
                <Link to={v.link} target={v.newTab && "_blank"}>
                  {v.name}
                </Link>
              </div>
            })}
          </div>
          {/* <div className=" col-md-3 p-0 m-0 d-flex justify-content-end right__section">
            {loginStatus ? <div className={`right__icon__section`}>
              <ButtonWithoutOutline
                text={<SearchIcon
                  size={25}
                  color={color} />}
                  classes={" only__login"}
                onClick={searchDrawerToggle} />
              <button onClick={connectionDrawer} className="pointer only__login position-relative connection__btn">
                {connectionReq.length > 0 && <div className="red__dot" />}
                <BellIcon size={23} color={color} />
              </button>
              <ButtonWithoutOutline
                reference={menuBtnRef}
                onClick={() =>
                  userClick(windowWidth)}
                text={<UserIcon size={25}
                  color={color} />} />
              {menuOpen && loginStatus && <div ref={menuRef} className="user__menu">
                <ButtonWithoutOutline onClick={() => navigation("/profile/edit/overview")} color={color} text={"Your Account"} />
                <ButtonWithoutOutline onClick={logout} color={color} text={"Logout"} />
                <div className="d-flex gap-4 mt-2">
                  {linksWithIcons.map(({ link, Icon }, index) => {
                    return <a href={link} target="_blank" rel="noreferrer" key={index}>
                      <Icon color={color} />
                    </a>
                  })}
                </div>
              </div>}
            </div>:
            <div className="right__icon__section">
              <ButtonWithoutOutline
                reference={menuBtnRef}
                onClick={() =>
                  userClick(windowWidth)}
                text={<UserIcon size={25}
                  color={color} />} />
            </div>
            }
          </div> */}
        </div>
      </div>
      <ConnectionRequests
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        fetchConnections={fetchConnections}
      />
      <SearchDrawer
        setOpenDrawer={setOpenSearchDrawer}
        openDrawer={openSearchDrawer}
      />
      <div className="navbar__drawer">
        {windowWidth < 769 && (
          <NavbarDrawer
            loginStatus={loginStatus}
            login={login}
            setOpenDrawer={setOpenNavbar}
            openDrawer={openNavbar}
            logout={logout}
            connectionDrawer={connectionDrawer}
            searchDrawerToggle={searchDrawerToggle}
          />
        )}
      </div>
    </div>
  );
};

export default NavbarCustom;
