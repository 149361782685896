import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//import components and functions
import { Navbar, RoundedBtn } from "../../components";
import { useNavigate } from "react-router-dom";
import { getInitialScreen } from "../../functions/common";

//import styles
import "../Login/Login.scss";

const LinkExpired = () => {
  const navigate = useNavigate();
  const { windowWidth } = useSelector((state) => state);
  const [size, setSize] = useState(getInitialScreen);
  // useState
  const loginSubmit = async () => {
    navigate("/login");
  };

  useEffect(() => {
    setSize(getInitialScreen(true))
  }, [windowWidth])

  return (
    <div style={{ width: "100%" }} className="m-auto">
      <Navbar />
      <div className="login__container">
        <div className="input__section">
          <div className="heading_login">Link has expired</div>
          <div className="description expire__description">
            Your login link has expired. Please try and login again.
          </div>
          <div className="w-100 mt-3 d-flex justify-content-center">
            <RoundedBtn
              onClick={loginSubmit}
              classes="mt-2 custom__f__size"
              text={"Login"}
              bgYellow={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkExpired;
