import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//import components from dependencies
import { Drawer, Space } from "antd";

// import components and functions
import { CloseIcon } from "../../assets/Icons";
import Dropdown from "../inputs/Dropdown";
import MultiSelect from "../inputs/MultiSelect";
import { fetchMe, searchUser } from "../../api/api";
import SearchResultCard from "../cards/SearchResultCard";

//import styles and variables
import { searchByDropdown, texts, theme } from "../../constants/data";
import "./Drawer.scss";

const SearchDrawer = ({ openDrawer, setOpenDrawer }) => {
  const windowWidth = useSelector((state) => state.windowWidth);

  const navigate = useNavigate()

  const [value, setValue] = useState("");
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchType, setSearchType] = useState("name");
  const [selectedMeetings, setSelectedMeetings] = useState([]);

  const onClose = () => {
    setOpenDrawer(false);
  };

  //handle change input
  const handleChange = (e) => {
    setValue(e.target.value);
  }

  //handle change searchby dropdown
  const changeSearchBy = (e) => {
    setSearchType(e.target.value);
    setSelectedMeetings([])
    if (e.target.value === "meeting") {
      setValue("")
      fetchMyData()
    }
  }

  const closeSearch = () => {
    setValue("")
    setSearchType("name")
    setUsers([])
    setSelectedMeetings([])
  };

  //fetch my data for show meetings
  const fetchMyData = async () => {
    const response = await fetchMe()
    if (response.success) {
      setEvents(response?.user?.eventAttended)
    }
  }

  const userSearch = async (text) => {
    const response = await searchUser({ keyword: text })
    setUsers(response?.mergedResults)
  }

  useEffect(() => {
    if (searchType === "meeting") {
      selectedMeetings.length > 0 ? userSearch(selectedMeetings) : setUsers([])
    } else {
      value?.length > 1 ? userSearch(value) : value.length === 0 && setUsers([]);
    }
  }, [value, selectedMeetings])

  useEffect(() => {
    const filtered = users?.filter((v) => v?.matchType === searchType && v.profileImageFull && v.profileImageFull !== "")
    setFilteredUsers(filtered)
  }, [users, searchType])

  return (
    <>
      <Space></Space>
      <Drawer
        title={
          <div className="title__container search__title__container">
            <div className="center__line"></div>
            <div>Search Community</div>
          </div>
        }
        placement="right"
        width={windowWidth > 600 ? 600 : windowWidth}
        onClose={onClose}
        open={openDrawer}
        closeIcon={<CloseIcon color={"#1A1F36"} />}
      >
        <div className="search__bar__main">
          <div>
            <Dropdown
              selectedOption={searchType}
              onChange={changeSearchBy}
              options={searchByDropdown}
            />
          </div>
          {searchType === "meeting" ? <div className="w-100">
            <MultiSelect
              location={location}
              selectedMeetings={selectedMeetings}
              setSelectedMeetings={setSelectedMeetings}
              classes="navbar__input"
              closeSearch={closeSearch}
              meetings={events}
            /></div> : <div className="position-relative w-100">
            <input
              onChange={handleChange}
              placeholder={`Search by ${searchType === "name" ? "Name" : "Verbs"
                }`}
              className="form-control navbar__input"
              autoFocus={true}
              value={value}
            />
            {value.length > 0 && (
              <div onClick={() => closeSearch()} className="close__btn">
                <CloseIcon size={7} color={theme.white} />
              </div>
            )}
          </div>}
        </div>
        {filteredUsers?.length > 0 ?
          <div className="search__result__section">
            {filteredUsers.map((user, index) => {
              return <SearchResultCard setOpenDrawer={setOpenDrawer} data={user} key={index} navigate={navigate} border={index !== 0} />
            })}
          </div> :
          <div className="search__result__section empty">
            <div className="search__container">
              <div className="no__search"
                dangerouslySetInnerHTML={{ __html: value === "" && selectedMeetings.length < 1 ? texts.notSearchTitle : texts.noSearchResultTitle(value) }}
              />
              <div className="no__search__description">
                {value === "" && selectedMeetings.length < 1 ?
                  texts.notSearchDescription : texts.noSearchResultDescription}
              </div>
              {value !== "" || selectedMeetings.length > 0 ? <div className="d-flex justify-content-center">
                <button onClick={closeSearch} className="something__else__btn">
                  Search something else
                </button>
              </div> : ""}
            </div>
          </div>}
      </Drawer>
    </>
  );
};
export default SearchDrawer;
