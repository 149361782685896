import React from 'react'
import { FooterNew, NewNavbar } from '../../components';
import subFooterPrefix from '../../assets/images/subfooterPrefix.png';
import './Privacy.scss'

const Index = () => {
  const privacyPolicy = [
    {
      heading: "1. Introduction",
      content: "The Meetings Company (\"we\", \"us\", \"our\") respects your privacy and is committed to protecting your personal data. This privacy policy will inform you how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.",
    },
    {
      heading: "2. What data do we collect?",
      content: "We may collect, use, store, and transfer different kinds of personal data about you, which we have grouped together as follows:",
      list: [
        "Identity Data includes first name, last name, username or similar identifier, and title.", ,
        "Contact Data includes billing address, delivery address, email address, and telephone numbers.",
        "Financial Data includes bank account and payment card details.",
        "Transaction Data includes details about payments to and from you and other details of products and services you have purchased from us.",
        "Technical Data includes internet protocol (IP) address, your login data, browser type, and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.",
        "Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback, and survey responses.",
        "Usage Data includes information about how you use our website, products, and services.",
        "Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.",
      ]
    },
    {
      heading: "3. How do we collect your data?",
      content: "We use different methods to collect data from and about you including through:",
      secondContent: "Direct interactions. You may give us your Identity, Contact, and Financial Data by filling in forms or corresponding with us by post, phone, email, or otherwise. This includes personal data you provide when you:",
      list: [
        " apply for our products or services;",
        "create an account on our website;",
        "subscribe to our service or publications;",
        "request marketing to be sent to you;",
        "enter a competition, promotion, or survey; or",
        "give us feedback or contact us.",
        "Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data using cookies, server logs, and similar technologies."
      ]
    },
    {
      heading: "4. How will we use your data?",
      content: "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
      list: [
        "Where we need to perform the contract, we are about to enter or have entered with you.",
        "Where it is necessary for our legitimate interests(or those of a third party) and your interests and fundamental rights, do not override those interests.",
        "Where we need to comply with a legal obligation."
      ]
    },
    {
      heading: "5. How do we store your data?",
      content: "We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed unauthorizedly, altered, or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions and are subject to a duty of confidentiality.",
    },
    {
      heading: "6. Marketing",
      content: "We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We have established the following personal data control mechanisms:",
      secondContent: "Promotional offers from us: We may use your Identity, Contact, Technical, Usage, and Profile Data to form a view of what we think you may want or need or what may interest you. This is how we decide which products, services, and offers may be relevant for you (we call this marketing).",
      thirdContent: "Third-party marketing: We will get your express opt-in consent before we share your personal data with any company outside The Meetings Company for marketing purposes."
    },
    {
      heading: "7. What are your data protection rights?",
      content: "Under certain circumstances, you have rights under data protection laws in relation to your personal data. These include the right to:",
      list: [
        "Request access to your personal data.",
        "Request correction of your personal data.",
        "Request the erasure of your personal data.",
        "Object to processing of your personal data.",
        "Request restriction of processing your personal data.",
        "Request transfer of your personal data.",
        "Right to withdraw consent."

      ]
    },
    {
      heading: "8. Changes to our privacy policy",
      content: "Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently for any updates or changes to our privacy policy.",
    },
    {
      heading: "9. How to contact us",
      content: "If you have any questions about The Meetings Company's privacy policy, or the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us at info@lifeismeeting.com.",
      secondContent: "This policy was last updated on 07/21/2023"
    },
  ]

  return (
    <div className='privacy__main'>
      <NewNavbar />
      <div className='other__sections'>
        <div className='heading privacy__heading'>Privacy Policy for The Meetings Company</div>
        <div className='content__text'>
          {privacyPolicy?.map((policy, index) => {
            return <div key={index}>
              <div className='sub__heading'>
                {policy.heading}
              </div>
              <div >
                {policy.content}
              </div>
              {policy.secondContent && <div>
                {policy.secondContent}
              </div>}
              {policy.thirdContent && <div className='m-0'>
                {policy.thirdContent}
              </div>}
              {policy.list && <ul>
                {policy.list.map((v, i) => <li key={i}>{v}</li>)}
              </ul>}
            </div>
          })}
        </div>
      </div>
      <FooterNew title={`Stay in the loop with us as our network <span>grows.<img src=${subFooterPrefix} /></span>`} />
    </div>
  )
}

export default Index