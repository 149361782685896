import React from "react";

const TextInput = ({
  onchange,
  label,
  name,
  placeholder,
  classes,
  required,
  textarea,
  inputClasses,
  maxLengthRequire,
  max,
  value
}) => {
  const maxLength = max ?? 300;
  return (
    <div className={`w-100  ${classes}`}>
      {label && (
        <label className="input__label">
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {textarea ? (
        <div>
          <textarea
            onChange={onchange}
            placeholder={placeholder}
            name={name}
            maxLength={maxLength}
            className={`form-control w-100 ${!label && "mt-3"}`}
            value={value}
          />
        </div>
      ) : (
        <input
          onChange={onchange}
          placeholder={placeholder}
          name={name}
          className={`form-control w-100 ${!label && "mt-3"} ${inputClasses}`}
          maxLength={maxLengthRequire && max}
          value={value}
        />
      )}
      {textarea || maxLengthRequire ? (
        <div className="d-flex justify-content-end text__area__length">
          {value?.length ?? 0}/{maxLength}
        </div>
      ) : null}
    </div>
  );
};

export default TextInput;
