import React from 'react';
import { Pagination } from 'antd';
import { PaginationLeftArrow, PaginationRightArrow } from '../../assets/Icons';
import "./pagination.scss"

const Index = ({ onChangePage, currentPage, totalItems, limit }) => {
    return (
        <Pagination
            onChange={onChangePage}
            hideOnSinglePage
            current={currentPage}
            total={totalItems}
            pageSize={limit}
            showSizeChanger={false}
            showQuickJumper={false}
            nextIcon={<div className='pagination__btn'><PaginationLeftArrow /></div>}
            prevIcon={<div className='pagination__btn'><PaginationRightArrow /></div>}
        />
    )
}

export default Index