import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//import components and functions
import { Navbar, Spinner, Notification, RoundedBtn } from "../../components";
import { getInitialScreen, validateEmail } from "../../functions/common";
import { sendMagicLink } from "../../api/api";
import { addEmail } from "../../functions/localstorage";

//import styles and variables
import { theme } from "../../constants/data";
import "./Login.scss";

const Login = () => {
  //router and redux hooks
  const navigate = useNavigate();
  const notificationRef = useRef();
  const { windowWidth } = useSelector((state) => state);

  // states
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(getInitialScreen);

  //login function
  const loginSubmit = async () => {
    setIsLoading(true);
    const data = { emailAddress: email };
    const response = await sendMagicLink(data);
    setIsLoading(false);
    if (response.success) {
      addEmail(email)
      navigate("/login-link");
    } else {
      notificationRef.current.openNotification(response?.message, false);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  useEffect(() => {
    setSize(getInitialScreen)
  }, [windowWidth])

  return (
    <div className="m-auto" style={{ width: "100%" }}>
      <Navbar />
      <div className="login__container">
        <div className="input__section">
          <div className="heading_login">Login</div>
          <div className="description mt-3">
            Enter your email to get a login code sent to your email.
          </div>
          <div className="w-100 login__input">
            <input
              onChange={handleChange}
              value={email}
              placeholder="EMAIL"
              className="form-control"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (validateEmail(email) && !isLoading) {
                    loginSubmit();
                  }
                }
              }}
            />
             <RoundedBtn
              onClick={loginSubmit}
              bgYellow={true}
              classes="w-100 my-2 desktop_btn"
              text={
                isLoading ? (
                  <Spinner color={theme.white} />
                ) : (
                  "Request Login Code"
                )
              }
              disabled={!validateEmail(email) || isLoading}
            />
          </div>
          <RoundedBtn
              onClick={loginSubmit}
              bgYellow={true}
              classes="w-100 mt-4 mobile_btn"
              text={
                isLoading ? (
                  <Spinner color={theme.white} />
                ) : (
                  "Request Login Code"
                )
              }
              disabled={!validateEmail(email) || isLoading}
            />
        </div>
            <Notification notificationRef={notificationRef} />
      </div>
    </div>
  );
};

export default Login;
