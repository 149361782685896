import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { LeftArrow, RightArrow } from "../../assets/Icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Carousel.scss";

// import required modules
import { Navigation, A11y } from "swiper/modules";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Swipper = ({ items }) => {
  const [itemsArr, setItemsArr] = useState(items);
  const { windowWidth } = useSelector((state) => state);
  const [activeSlides, setActiveSlides] = useState(
    windowWidth > 1024 ? [0, 1, 2] : windowWidth > 768 ? [0, 1] : [0]
  );

  const handleSlideChange = (swiper) => {
    const { activeIndex } = swiper;
    const count = windowWidth > 768 ? 2 : 1
    const lastIndex = activeIndex + count ;

    if (lastIndex <= swiper.slides.length) {
      const ind = windowWidth > 1024 ? 3 : windowWidth > 768 ? 2 : 1;
      setActiveSlides(Array.from({ length: ind }, (_, i) => activeIndex + i));
    }
  };

  useEffect(() => {
    if (windowWidth > 768) {
      const newArr = [...items];
      newArr.push({ img: null });
      setItemsArr(newArr);
      setActiveSlides(windowWidth > 1024 ? [0, 1, 2] : [0, 1]); 
    } else {
      setActiveSlides([0]);
    }
  }, [items]);


  return (
    <>
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={windowWidth < 525 ? 15 : 35}
        slidesPerView={windowWidth < 769 ? 1 : windowWidth < 1025 ? 2 : 3}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
        }}
        pagination={{ clickable: true }}
        onSwiper={handleSlideChange}
        onSlideChange={handleSlideChange}
      >
        {itemsArr?.map((v, i) => (
          <SwiperSlide key={i}>
            <div
              className={`${
                activeSlides.includes(i) && "active__custom"
              } review__card__main`}
            >
              {v.img && <img className="review__img" src={v.img} />}
              <div className="review__section__new">
                <div className="review__subsection">
                  <div className="mesg">{v.review?.message}</div>
                  <div className="name">{v.review?.name}</div>
                  <div className="address">{v.review?.address}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {windowWidth > 768 && (
          <div className="d-flex justify-content-between mx-1 arrow__section">
            <button className="custom-prev-button btn-custom-new">
              <LeftArrow />
            </button>
            <button className="custom-next-button btn-custom-new">
              <RightArrow />
            </button>
          </div>
        )}
      </Swiper>
    </>
  );
};

export default Swipper;
