import React from 'react';
import { Link } from 'react-router-dom';
import dotsSvg from '../../assets/images/svgImages/stairDots.svg';
import RoundedButton from '../buttons/RoundedBtn';
import footerLogo from '../../assets/images/svgImages/footerLogo.svg';
import { community, company, policy, social, socialNew } from '../../constants/footerData';
import { useSelector } from 'react-redux';
import './Footer.scss';
import { links } from '../../constants/data';


const FooterNew = ({ title }) => {

    const { windowWidth } = useSelector(state => state);

    const formPoints = [
        "News and updates",
        "Free ideas and articles",
        "No bad vibes"
    ]

    const subscribe = () => {
        window.open(links.media)
    }

    return (
        <div className='footer__new'>
            {/* subfooter section start */}
            <div className='sub__footer__section row m-0 p-0'>
                <div className='other__section sub__footer__form'>
                    <div className='col-md-6'>
                        <div dangerouslySetInnerHTML={{ __html: title }} className='sub__footer__heading'>
                        </div>
                        <div className='sub__footer__input__section w-100'>
                            <RoundedButton onClick={subscribe} text='Subscribe' bgYellow />
                        </div>
                        <div className='point__section'>
                            {formPoints.map((point, index) => (
                                <div key={index} className='d-flex align-items-center'>
                                    <div className='bullet' />
                                    <div className='point__text'>{point}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <img src={dotsSvg} className='stair__dots' />
            </div>
            {/* subfooter section end */}
            <div className='bg__yellow'>
                {/* footer section start */}
                <div className='new__footer__section__main row m-0'>
                    <div className='col-md-5 m-0 p-0'>
                        <img src={footerLogo} />
                    </div>
                    <div className='col-md-7 d-flex m-0 p-0 footer__list__section'>
                        {windowWidth > 768 && <div className='col-md-4'>
                            <div className='footer__list__heading'>
                                LEGAL
                            </div>
                            {policy?.map((v, i) => <div key={i} className='footer__list__text'><a target={v.newTab && "_blank"} href={v.link}>{v?.text}</a></div>)}
                        </div>}
                        <div className='col-md-4'>
                            <div className='footer__list__heading'>
                                Community
                            </div>
                            {community?.map((v, i) => <div key={i} className='footer__list__text'><Link target={v.newTab && "_blank"} to={v.link}>{v?.text}</Link></div>)}
                        </div>
                        <div className='col-md-4'>
                            <div className='footer__list__heading'>
                                Company
                            </div>
                            {company?.map((v, i) => <div key={i} className='footer__list__text'>
                                {v.mailTo ? <a href={v.link}>{v?.text}</a>
                                    : <Link target={v.newTab && "_blank"} to={v.link}>{v?.text}</Link>}
                            </div>)}

                            {windowWidth > 768 ? <div>
                                <div className='footer__list__heading mt-4'>
                                    Social
                                </div>
                                {social?.map((v, i) => <div key={i} className='footer__list__text'><Link target={v.newTab && "_blank"} to={v.link}>{v?.text}</Link></div>)}
                            </div> : ""}
                        </div>
                    </div>
                    {windowWidth < 769 && <div className='w-100 d-flex p-0 m-0'>
                        <div className='w-50'>
                            <div className='mt-4'>
                                <div className='footer__list__heading'>
                                    LEGAL
                                </div>
                                {policy?.map((v, i) => <div key={i} className='footer__list__text'><a target={v.newTab && "_blank"} href={v.link}>{v?.text}</a></div>)}
                            </div>
                        </div>
                        <div className='w-50'>
                            <div className='footer__list__heading mt-4'>
                                Social
                            </div>
                            {social?.map((v, i) => <div key={i} className='footer__list__text'><Link target={v.newTab && "_blank"} to={v.link}>{v?.text}</Link></div>)}
                        </div>
                    </div>}
                </div>
                {/* footer section end */}
                {/* copyright section start */}

                <div className='copy__right__section other__section d-flex align-items-center justify-content-between'>
                    <div>©2023 The Meetings Company. All rights reserved.</div>
                    <div className='d-flex align-items-center gap-4'>
                        {socialNew.map(({ Icon, link }, i) => <Link target="_blank" to={link}><Icon key={i} /></Link>)}
                    </div>
                </div>

                {/* copyright section end */}
            </div>
        </div>
    )
}

export default FooterNew