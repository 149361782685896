import React from "react";

const Dropdown = ({ options, onChange, selectedOption }) => {
  return (
    <select onChange={onChange} className="form-control custom__select">
      {options.length > 0 &&
        options.map((v, i) => (
          <option selected={selectedOption === v.value} key={i} value={v.value}>
            {v.name}
          </option>
        ))}
    </select>
  );
};

export default Dropdown;
