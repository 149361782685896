import React from "react";
import {
  slack,
  google,
  microsoft,
  stripe,
  netflix,
  disney,
  adobe,
  next
} from '../../../assets/images/svgImages';
import './components.scss'
import { useSelector } from "react-redux";

const TrustedBy = () => {

  const {windowWidth} = useSelector(state => state)
  const itemsPerRow = windowWidth > 525 ? 4 : 2

  const trustedBy = [
    {image:slack},
    {image:stripe},
    {image:google},
    {image:netflix},
    {image:next},
    {image:adobe},
    {image:disney},
    {image:microsoft},
  ]

  return (
    <div className="trusted__section__main">
      <div className="heading__text"><span>Trusted by the</span> very best</div>
      <div className="icons__area">
        {Array.from({length:Math.ceil(trustedBy.length / itemsPerRow)}).map((v, i) => {
          console.log("Length",itemsPerRow*i,itemsPerRow* (i+1))
          return <div className="d-flex flex-wrap trusted__icons__section">
            {trustedBy.slice(itemsPerRow*i,itemsPerRow * (i+1)).map((item, index) => (
              <div><img src={item.image} /></div>
            ))}
          </div>
        })}
      </div>
    </div>
  );
};

export default TrustedBy;
