//get base64 from file
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

//validate email
export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// change screen title
export const changeTitle = (location) => {
  const path = location.pathname;
  if (path === "/") {
    document.title = "Life is Meeting | Meet gracefully, positively and collaboratively"
  } else if (path === "/profile/edit/overview" || path === "/profile/edit/about") {
    document.title = "Your Account | Life is Meeting"
  } else if (path === "/login") {
    document.title = "Login | Life is Meeting"
  } else if (path === "/search") {
    document.title = "Search | Life is Meeting"
  } else if (path === "/login-link") {
    document.title = "Verify | Life is Meeting"
  } else if (path === "/link-expired") {
    document.title = "Link Expired | Life is Meeting"
  }
  else {
    document.title = "Life is Meeting | Meet gracefully, positively and collaboratively"
  }
}

// get initial screen size
export const getInitialScreen = (clientWidth) => {
  const width =clientWidth ? document.documentElement.clientWidth :  window.innerWidth;
  const height = window.innerHeight;
  const newWidth = Math.floor(width / 20) * 20;
  const newHeight = Math.floor(height / 20) * 20;
  return { height: newHeight, width: newWidth }
}