import React, { useRef, useState } from "react";

//import components and functions
import { Navbar, Notification, Pagination } from "../../components/index.js";

//import styles and variables
import "./Moderation.scss";
import { TestimonialsCommas } from "../../assets/Icons.js";
import { PopupButton } from "react-calendly";
import { testimonials } from "../../constants/data.js";

const Members = () => {

  const notificationRef = useRef();
  const testimonialRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);


  const onChangePage = (page) => {
    setCurrentPage(page);
    testimonialRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="moderation__main">
      <Navbar home />
      <div className="moderation__main__container">
        <div className="moderation__text__container">
          <div className="moderation__main__heading">
            Moderation
          </div>
          <div className="moderation__main__description">
            We provide impeccable moderation as a service to companies, communities and conversations who want to play gracefully with ideas.
          </div>
          <div>
            <PopupButton
              url="https://calendly.com/albylifeismeeting/30-minute-meeting?embed_type=PopupWidget&embed_domain=1"
              rootElement={document.getElementById("root")}
              text="Schedule a call"
              className="schedule__cal__btn"
            />
          </div>
          <div className="testimonial__section">
            <div ref={testimonialRef} className="testimonial__box">
              <div className="navy__blue__bg">
                <div className="testimonial__inner__box">
                  <div className="testimonial__message">
                    {testimonials?.[currentPage - 1]?.message}
                  </div>
                  <div className="testimonial__name">
                    {testimonials?.[currentPage - 1]?.name}, {testimonials?.[currentPage - 1]?.position}
                  </div>
                  <div className="testimonial__commas">
                    <TestimonialsCommas />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination__section">
            <Pagination onChangePage={onChangePage} currentPage={currentPage} totalItems={testimonials?.length} limit={1} />
          </div>
        </div>
      </div>
      <Notification notificationRef={notificationRef} />
    </div>
  );
};

export default Members;
