import { links } from "./data";

export const navbarLinks = (pricingRef) => {
  return [
    {
      name: "HOME",
      link: "/splash",
      type: "link",
    },
    {
      name: "Creators",
      type: "dropdown",
    },
    {
      name: "PRICING",
      link: "/",
      type: "linkScroll",
      scrollRef: pricingRef,
    },
    {
      name: "MEDIA & MEETINGS",
      link: links.media,
      type: "link",
      newTab: true,
    },
    {
      name: "FOR COMPANIES",
      link: "/companies",
      type: "link",
      newTab: false,
    },
  ];
};

export const dropdownItems = [
  {
    name: "Event creators",
    description: "Create meetings in your city and help build our community.",
    link: links.eventCreators,
    color: "#FFCD00",
  },
  {
    name: "Hosts",
    description: "Open your space to meeting guests.",
    link: links.host,
    color: "#0078F3",
  },
  {
    name: "Moderators",
    description:
      "Guide our meetings to create thoughtful and challenging conversations.",
    link: links.moderators,
    color: "#FC6D51",
  },
];
