import React, { useRef, useState } from "react";

//import components and functions
import { Navbar, Notification } from "../../components/index.js";

//import styles and variables
import "./Meetings.scss";
import { meetings } from "../../constants/data.js";
import { Link } from "react-router-dom";

const Members = () => {

  const notificationRef = useRef();

  const [activeTab, setActiveTab] = useState("upcoming")

  const goToLink = (link) => {
    window.open(link, '_blank');
  }

  const changeMeetingTab = (tab) => setActiveTab(tab)

  return (
    <div className="meetings__main">
      <Navbar home />
      <div className="meetings__main__container">
        <div className="meetings__text__container">
          <div className="meetings__main__heading">
            Meetings
          </div>
          <div className="d-flex gap-3 mt-3 ">
            <button className={`meeting__tab ${activeTab === "upcoming" ? "active__meeting__tab" : ""}`} onClick={() => changeMeetingTab("upcoming")}>
              UPCOMING MEETINGS
            </button>
            <button className={`meeting__tab ${activeTab === "previous" ? "active__meeting__tab" : ""}`} onClick={() => changeMeetingTab("previous")}>
              PREVIOUS MEETINGS
            </button>
          </div>
          <div className="testimonial__section">
            {meetings?.filter((m) => {
              const date = new Date(m.exactDate)
              date.setHours(19)
              if (activeTab === "upcoming") {
                return new Date(date) >= new Date()
              } else {
                return new Date(date) < new Date()
              }
            })?.map((v, i) => {
              return <button className="testimonial__btn" key={i} onClick={() => goToLink(v?.link)}>
                <div className="testimonial__box">
                  <div className="navy__blue__bg">
                    <div className="testimonial__inner__box">
                      <div className="date__section">
                        <div className="month">{v?.month}</div>
                        <div className="date">{v?.date}</div>
                        <div className="time">{v?.time}</div>
                      </div>
                      <div className="title__section">
                        <div className="location">{v?.location}</div>
                        <div className="title">{v?.title}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            })}
          </div>
        </div>
      </div>
      <Notification notificationRef={notificationRef} />
    </div>
  );
};

export default Members;
