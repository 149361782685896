import { getToken } from "../functions/localstorage";
import { GET_METHOD, PATCH_METHOD, POST_METHOD, PUT_METHOD } from "./axiosMethods";
import { CLOUDINARY_API, CLOUD_NAME } from "./config";
import axios from "axios";

const sendError = (error) => {
  return { success: false, error: error.message };
}

const getHeaders = () => ({
  Authorization: getToken(),
});

//image upload on cloudinary
export const imageUpload = async (file) => {
  let apiUrl = CLOUDINARY_API;

  let form = new FormData();
  form.append("file", file);
  form.append("upload_preset", "profileImageFull");
  form.append("cloud_name", CLOUD_NAME);

  const response = await axios.post(apiUrl, form);
  if (response.status === 200) {
    return response.data.secure_url;
  }
};

//send magic link
export const sendMagicLink = async (postData) => {
  try {
    const { data } = await POST_METHOD("/user/send-magic-link", postData);
    return data;
  } catch (error) {
    return sendError(error)
  }
};

//send magic link
export const verifyMagicLink = async (postData) => {
  try {
    const { data } = await POST_METHOD("/user/verify-token", postData);
    return data;
  } catch (error) {
    return sendError(error)
  }
};

//get my data
export const fetchMe = async () => {
  try {
    const { data } = await GET_METHOD("/user/me", { headers: getHeaders() });
    return data;
  } catch (error) {
    return sendError(error)
  }
};

// update profile
export const updateProfile = async (payloadData) => {
  try {
    const { data } = await PATCH_METHOD(
      "/user/update-airTableRecords",
      payloadData,
      { headers: getHeaders() }
    );
    return data;
  } catch (error) {
    return sendError(error)
  }
};


// get all users on home screen
export const fetchAllUsers = async () => {
  try {
    const { data } = await GET_METHOD("all-users")
    if (data.success) {
      return data.users
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

//fetch user details
export const fetchUserDetails = async (id) => {
  try {
    const { data } = await GET_METHOD(`get-user/${id}`, { headers: getHeaders() })
    if (data.success) {
      return data.user
    } else {
      return {}
    }
  } catch (error) {
    return {}
  }
}

//send connection request
export const sendConnectRequest = async (payload) => {
  try {
    const { data } = await POST_METHOD(`user/connection-request`, payload, { headers: getHeaders() })
    return data
  } catch (error) {
    return sendError(error)
  }
}

//update connection request
export const updateConnectRequest = async (payload) => {
  try {
    const { data } = await PUT_METHOD(`user/connection-request-update/${payload.id}`, payload, { headers: getHeaders() })
    return data
  } catch (error) {
    return sendError(error)
  }
}

//get all connection requests
export const getConnectionRequests = async () => {
  try {
    const { data } = await GET_METHOD(`user/get-connection-requests`, { headers: getHeaders() })
    return data
  } catch (error) {
    return sendError(error)
  }
}

//search
export const searchUser = async (payload) => {
  try {
    const { data } = await POST_METHOD(`user/search`, payload, { headers: getHeaders() })
    return data
  } catch (error) {
    return sendError(error)
  }
}