import React, { useRef } from "react";

//import components
import { FooterNew, NewNavbar } from "../../components";
import { HeroSection, Review, SubHero } from "../../layouts";
import SliderSection from "./components/SliderSection";
import TrustedBy from "./components/TrustedBy";
import IntroSection from "./components/IntroSection";

//import styles and images
import ringImage from "../../assets/images/svgImages/companiesballs.svg";
import subFooterPrefix from '../../assets/images/subfooterPrefix.png';
import "./HomeNew.scss";

const Index = () => {
  const pricingRef = useRef();

  return (
    <div className="home__new__main">
      <NewNavbar pricingRef={pricingRef} />
      <div className="other__section">
        <HeroSection
          image={ringImage}
          heading={"<span>Incurious</span> companies fail"}
          description="We work with your team to help them <span>play gracefully with ideas together</span> - so you can build a culture of <span>curiosity, collaboration,</span> and <span>creativity</span> that helps your business succeed."
          btnText="Book a free consultation today"
        />
        <SubHero
          heading={
            "Asking the right questions is a muscle - we train your people to exercise it."
          }
          description={
            "Powered by the expert moderators from <span>Life Is Meeting</span> - the global meetings community. We have moderated meetings for over <span>20,000 participants</span> across <span>three continents.</span>"
          }
          secondDescription="By helping your team practice playing gracefully ideas together we build a culture that thrives on curiosity, collaboration and creativity."
        />
        <TrustedBy />
      </div>
      <IntroSection sliderClasses="companies__intro" />
      <SliderSection />
      <div className="other__section">
        <Review
        description="Build a company culture or curiosity, collaboration and creativity that helps your business succeed."
        />
      </div>
      <FooterNew title={`Not ready to join <span>quite<img src=${subFooterPrefix} /></span> yet?`} />
    </div>
  );
};

export default Index;
