import React from 'react';
import card1 from '../../../assets/images/jpg/card1.jpg';
import card2 from '../../../assets/images/jpg/card2.jpg';
import card3 from '../../../assets/images/jpg/card3.jpg';
import meetingPrefix from '../../../assets/images/meetingPrefix.png';
import mediaPrefix from '../../../assets/images/mediaPrefix.png';
import networkPrefix from '../../../assets/images/networkPrefix.png';
import { useSelector } from 'react-redux';
import { IntroCarousel, NewCarousel } from '../../../components';

const IntroCardsSection = () => {

    const {windowWidth} = useSelector(state => state)

    const cardData = [
        {
            image: card1,
            prefixImage: meetingPrefix,
            heading: "Meetings",
            description: "Intimate in-person participatory moderated conversations at the nexus of safety and tension in cities around the world.",
            list: ["Diverse guests", "Moderated group conversations", "Thought provoking questions"],
            bulletColor: "#FFCD00",
            imageSide: "left"
        },
        {
            image: card2,
            prefixImage: mediaPrefix,
            heading: "Media",
            description: "Thought provoking fact based media so you can decide how you could think about ideas.",
            list: ["New articles every week", "Think clearly and independently", "Perspectives from people not like you"],
            bulletColor: "#0078F3",
            imageSide: "right"
        },
        {
            image: card3,
            prefixImage: networkPrefix,
            heading: "Network",
            description: "Access and collaborate with a group of interesting and interested people who don’t all think, look or sound the same.",
            list: ["1,000+ members", "3+ cities", "Thousands of ideas"],
            bulletColor: "#FC6D51",
            imageSide: "left"
        },
    ]

    return (
        <div className='intro__card__section'>
            {windowWidth > 768 ? cardData.map((v, i) => (
                <div key={i} className='intro__card'>
                    {v.imageSide === "left" && <div className='card__img__section'>
                        <img src={v.image} />
                    </div>}
                    <div className='card__text__section'>
                        <img src={meetingPrefix} />
                        <div className='card__heading'>
                            {v.heading}
                        </div>
                        <div className='card__description'>
                            {v.description}
                        </div>
                        {v.list.map((list, index) => (
                            <div key={index} className='list_'>
                                <div style={{backgroundColor:v.bulletColor}} className='bullet' />
                                <div className='bullet__point'>
                                    {list}
                                </div>
                            </div>
                        ))}

                    </div>
                    {v.imageSide === "right" && <div className='card__img__section'>
                        <img src={v.image} />
                    </div>}
                </div>
            )): <IntroCarousel items={cardData} />}
        </div>
    )
}

export default IntroCardsSection