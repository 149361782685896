import React, { useState, useRef, useEffect } from "react";

//import components and functions
import {
  BlackButton,
  Notification,
  Spinner,
  TextInput,
} from "../../../components";
import ImageUploader from "../../../components/inputs/ImageUploader";
import { CloseIcon } from "../../../assets/Icons";
import { getBase64 } from "../../../functions/common";
import { imageUpload, updateProfile } from "../../../api/api";
import { getOverviewValidtions } from "../../../functions/validations";

//import styles and variables
import { theme } from "../../../constants/data";
import "../EditProfile.scss";

const Personal = ({ data, getPayload, fetchUserData }) => {
  //refs
  const notificationRef = useRef();

  //states
  const [profile, setProfile] = useState("");
  const [file, setFile] = useState(null);
  const [personalData, setPersonalData] = useState({ userPersonalInfo: [] });
  const [isLoading, setIsLoading] = useState(false);
  const validations = getOverviewValidtions(personalData, file);

  //update profile function
  const updateOverview = async () => {
    setIsLoading(true);
    let image;
    if (file) {
      //upload image to cloudinary
      const uploadRes = await imageUpload(file);
      image = uploadRes;
    }
    const payload = getPayload(
      file
        ? {
          ...personalData,
          profileImageFull: image,
          profileImageThumbnail: image,
        }
        : { ...personalData },
      "overview"
    );
    const response = await updateProfile(payload);
    if (response.success) {
      notificationRef.current.openNotification("Your profile has been updated successfully!", true);
      setFile(null)
      fetchUserData()
    } else {
      notificationRef.current.openNotification("Something went wrong", false);
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalData((data) => ({ ...data, [name]: value }));
  };

  const fileChange = async (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      getBase64(e.target.files[0], (result) => {
        setProfile(result);
      });
    }
  };

  //cancel image
  const cancelImage = () => {
    setProfile("");
    setFile(null);
    setPersonalData((d) => ({
      ...d,
      profileImageFull: "",
      profileImageThumbnail: "",
    }));
  };

  useEffect(() => {
    const { city, firstName, lastName, emailAddress } = data;
    setPersonalData({
      firstName,
      lastName,
      city,
      profileImageThumbnail: data?.userPersonalInfo?.profileImageThumbnail,
      profileImageFull: data?.userPersonalInfo?.profileImageFull,
      emailAddress,
    });
    setProfile(data?.userPersonalInfo?.profileImageFull ?? "");
  }, [data]);

  return (
    <div className="input__section pb-4">
      <div className="w-100 d-flex justify-content-between flex-wrap">
        <div className="short__width mt-3">
          <TextInput
            required
            onchange={handleChange}
            label={"First Name"}
            name="firstName"
            value={personalData.firstName}
          />
        </div>
        <div className="short__width mt-3">
          <TextInput
            required
            onchange={handleChange}
            label={"Last Name"}
            name="lastName"
            value={personalData.lastName}
          />
        </div>
      </div>
      <div className="w-100 mt-3">
        <TextInput
          required
          onchange={handleChange}
          label={"Email"}
          name="emailAddress"
          value={personalData.emailAddress}
        />
        <ImageUploader
          required
          onchange={fileChange}
          label={"Profile Photo"}
          name="photo"
          classes="mt-3"
        />
        <div className="w-100 d-flex gap-2 mt-3">
          {profile !== "" && (
            <div className="attachment">
              <img src={profile} alt="profile" />
              <div onClick={cancelImage} className="close__container">
                <CloseIcon color="white" size={5} />
              </div>
            </div>
          )}
        </div>
        <TextInput
          required
          onchange={handleChange}
          label={"Where are you located?"}
          name="city"
          classes={"mt-3"}
          value={personalData.city}
        />
        <BlackButton
          onclick={updateOverview}
          classes="w-100 my-4 save__btn"
          text={isLoading ? <Spinner color={theme.white} /> : "Save"}
          disabled={!validations || isLoading}
        />
      </div>
      <Notification notificationRef={notificationRef} />
    </div>
  );
};

export default Personal;
