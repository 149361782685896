import React from "react";
import card1 from "../../../assets/images/jpg/card4.jpg";
import card2 from "../../../assets/images/jpg/card5.jpg";
import card3 from "../../../assets/images/jpg/card6.jpg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IntroCarousel } from "../../../components";

const IntroSection = ({sliderClasses}) => {
  const [active, setActive] = useState(0);
  const {windowWidth} = useSelector(state => state)

  const cardData = [
    {
      image: card1,
      heading: "Discovery",
      description:
        "By helping your team practice playing gracefully ideas together we build a culture that thrives on curiosity, collaboration and creativity.",
      count:"01"
      },
    {
      image: card2,
      heading: "Meeting",
      description:
        "Moderated in-person town hall-style meetings where we meet in person and play gracefully with ideas together at the nexus between safety and tension.",
      count:"02"
      },
    {
      image: card3,
      heading: "Review and Grow",
      description:
        "Follow-up conversations with stakeholders, meeting reports, and regular planning for the future to keep meeting like this.",
      count:"03"
      },
  ];

  const changeActive = (i)=> setActive(i)

  return (
    <div className="intro__card__section__companies">
      {windowWidth > 768 ? <div className="intro__card">
        <div className="card__img__section">
          <img src={cardData[active].image} />
        </div>
        <div className="card__text__section">
          {cardData.map((v, i) => (
            <div className="pointer" onClick={()=> changeActive(i)}>
              {i === active ? (
                <div className="card__main opened">
                  <div className="d-flex align-items-center heading__section">
                    <div className="count">{v.count}</div>
                    <div className="card__heading">{v.heading}</div>
                  </div>
                  <div className="card__description">{v.description}</div>
                </div>
              ) : (
                <div className="card__main">
                  <div className="d-flex align-items-center heading__section">
                    <div className="count">{v.count}</div>
                    <div className="card__heading">{v.heading}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>:<IntroCarousel sliderClasses={sliderClasses} items={cardData} />}
    </div>
  );
};

export default IntroSection;
