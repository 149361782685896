import React from 'react';
import { RoundedBtn } from '../../../components';
import { useState } from 'react';
import { Switch } from "antd";
import { useEffect } from 'react';

const Card = ({ data, duration }) => {
  return <div className='Card__main_section'>
    <div className='Card__main'>
      <div className='Cad__heading' dangerouslySetInnerHTML={{ __html: data.heading }} />
      <div className='card__pricing__section'>
        <div className='card__price'>
          ${data.charges}
        </div>
        <div className='Card__pricing_month'>
          /{duration === "monthly" ? "month" : "year"}
        </div>
      </div>
      <div className='btn__div'>
        <RoundedBtn text='Join Now' bgYellow classes=' w-100' />
      </div>
      <div className='card__content'>
        {data.list.map((item, index) => (<div key={index}>{item}</div>))}
      </div>
    </div>
  </div>
}

function MemberPricing({ pricingRef }) {

  const [duration, setDuration] = useState("monthly")

  const pricingCards = {
    heading: "Member",
    charges: "20",
    list: ["First & free access to meetings", "Access to exclusive media", "Introductions to the network"],
    btnText: "Join Now"
  }
  const pricingCardsAnnually = {
    heading: "Member",
    charges: "199",
    list: ["First & free access to meetings", "Access to exclusive media", "Introductions to the network"],
    btnText: "Join Now"
  }

  const toggleDuration = () => {
    setDuration(d => d === "monthly" ? "annualy" : "monthly")
    const targetElement = document.querySelector(".members_switch .ant-switch-handle"); // Replace 'yourElementId' with the actual element ID
    if (targetElement.classList.contains("monthly")) {
      targetElement.classList.add("annualy");
      targetElement.classList.remove("monthly");
    } else {
      targetElement.classList.add("monthly");
      targetElement.classList.remove("annualy");
    }
  }



  useEffect(() => {
    const targetElement = document.querySelector(".members_switch .ant-switch-handle"); // Replace 'yourElementId' with the actual element ID
    targetElement.classList.add("monthly");
  }, []);

  return (
    <div ref={pricingRef} className='Member__Section'>
      <div>
        <div className='heading__text'>
          Membership <span>Pricing</span>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='button__main__section'>
            {/* <RoundedBtn onClick={toggleDuration} text='MONTHLY' classes={duration === "monthly" ? 'bg__yellow' : "no_bg_no_border"} />
            <RoundedBtn onClick={toggleDuration} text='ANNUALY' classes={duration === "annualy" ? 'bg__yellow' : "no_bg_no_border"} /> */}
            <Switch
            rootClassName="rootClassName"
              onChange={toggleDuration}
              className="switch__new members_switch"
              checkedChildren={"MONTHLY"}
              unCheckedChildren={"ANNUALY"}
              defaultChecked={false}
            />
          </div>
        </div>
      </div>

      <div className='pading__setting'>
        <div className='row justify-content-center m-0 p-0'>
          <div className='col-md-6 m-0 p-0'>
            <Card duration={duration} data={duration === "monthly" ? pricingCards : pricingCardsAnnually} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberPricing