import React, { useState, useRef, useEffect } from "react";

//import components and variables
import { BsCheckLg } from "react-icons/bs";
import arrowIcon from "../../assets/icons/arrow-down.svg";
import { CloseIcon } from "../../assets/Icons";
import { theme } from "../../constants/data";

const MultiSelect = ({ classes, closeSearch, meetings, selectedMeetings, setSelectedMeetings }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  //toggle dropdown
  const toggleDropdown = () => {
    setOpen(!open);
  };

  useEffect(() => {
    //handler for click outside of dropdown
    function handler(event) {
      if (!dropdownRef.current?.contains(event.target)) {
        setOpen(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  //handle option check/uncheck
  const optionCheck = async (value) => {
    setOpen(true)
    if (selectedMeetings.includes(value)) {
      setSelectedMeetings((meetings) => {
        const selectedMeetings = meetings?.filter((m) => m !== value);
        selectedMeetings.length === 0 && closeSearch(true);
        return selectedMeetings;
      });
    } else {
      await setSelectedMeetings((meetings) => {
        return [...meetings, value];
      });
    }
  };


  return (
    <div ref={dropdownRef} className="position-relative">
      {selectedMeetings?.length > 0 ? (
        <div
          onClick={toggleDropdown}
          className={`form-control select__toggle ${classes}`}
        >
          {selectedMeetings?.length} selected
          <div onClick={() => closeSearch()} className="close__btn multi__select">
            <CloseIcon size={7} color={theme.white} />
          </div>
          <img src={arrowIcon} alt="arrow" className={open && "arrow__up"} />
        </div>
      ) : (
        <div
          onClick={toggleDropdown}
          className={`form-control select__toggle ${classes} placeholder__color`}
        >
          Select meeting(s)
          <img src={arrowIcon} alt="arrow" className={open && "arrow__up"} />
        </div>
      )}
      {open && (
        <div className="position-absolute multi__dropdown">
          {meetings?.length > 0 ? meetings?.map((v, i) => (
            <div
              key={i}
              onClick={() => optionCheck(v)}
              className="multi__options"
            >
              <div>{v}</div>
              <div
                className={`check ${selectedMeetings.includes(v) && "check__active"
                  }`}
              >
                <BsCheckLg color="#FFF" />
              </div>
            </div>
          )) : <div className="w-100 p-4 d-flex justify-content-center">You have no meeting.</div>}
        </div>
      )}
    </div>
  );
};
export default MultiSelect;
