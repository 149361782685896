import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//import components and functions
import { Navbar } from "../../components";
import { getInitialScreen } from "../../functions/common";

//import styles and assets
import "./Layout.scss";

const Index = ({ children,connectionRef , layoutSize,greyBgColor}) => {
  const { windowWidth } = useSelector((state) => state);
  const [size, setSize] = useState(getInitialScreen);

  useEffect(() => {
    setSize(getInitialScreen(true))
  }, [document.documentElement.clientWidth, windowWidth])

  return (
    <div style={{ width: size.width }} className={`m-auto content__container ${layoutSize && "h-auto"} ${greyBgColor && "bg__grey"}` }>
      <Navbar connectionRef={connectionRef} />
      <div className="content__main">
        {children}
      </div>
    </div>
  );
};

export default Index;
