import React from "react";

//import components and data
import BlackButton from "../buttons/BlackButton";
import { texts } from "../../constants/data";

const SearchResultCard = ({ data, border, navigate, setOpenDrawer }) => {
  const { fullName, profileImageFull } = data;

  // function call when user click on profile or user name
  const cardClick = () => {
    setOpenDrawer(false)
    navigate(`/profile/${data._id}`)
  }

  return (
    <div className={`search__card__main ${border && "border__top"}`}>
      <div>
        <img onClick={cardClick} src={profileImageFull} className="profile__image pointer" alt="profile" />
      </div>
      <div>
        <div onClick={cardClick} className="user__name pointer">{fullName}</div>
        <div className="user__question">{texts.verb}</div>
        <div className="ans__list">
          {data?.verbs?.map((v, i) => (
            <div className="verb__point">
              <div>
                <div className="bullet" />
              </div>
              <div className="user__answer">
                {v}
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex meeting__section">
          <div>
            <div className="attended__head">
            Attended:
            </div>
          </div>
          <div className="bt__container">
            {data?.eventAttended?.map((v, i) => <button key={i} className="meeting__btn">{v}</button>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultCard;
